import React, { Component, Fragment } from 'react';
import InventoryHeader from './InventoryHeader';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import moment from 'moment';

@inject('InventoryStore')
@inject('UserStore')
@observer
class Inventory extends Component {
    render() {
        const { UserStore } = this.props;
        const { position } = UserStore;
        
        return (
            <Fragment>
                <InventoryHeader title="Stock Count" backLink="" />
                <div className="inventory-menu">
                    <h2>{moment().format('dddd - DD/MM/YYYY')}</h2>
                        {
                            position && (
                                <div className="btn-wrapper mm">
                                    <Link
                                        to={{
                                            pathname: `/inventory/stock-count/components/final`,
                                            state: { itemType: 'Component' },
                                        }}
                                    >
                                        <button className="btn btn-primary width--300px">
                                            Start Stock Count
                                        </button>
                                    </Link>
                                </div>
                            )
                        }
                    <div className="btn-wrapper mm">
                        <Link
                            to={{
                                pathname: `/inventory/print-stock-count`,
                            }}
                        >
                            <button className="btn btn-primary width--300px">
                                Print Stock Count List
                            </button>
                        </Link>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default Inventory;
