import React, { Component, Fragment } from 'react';
import { WhiteLogoWhiteLeaf, WhitePopLogo } from '../components/BrandLogo';
import Loading from '../components/Loading';
import CountrySelector from './CountrySelectorComponent';
import { inject, observer } from 'mobx-react';
import Select from 'react-select';
import { getCity } from '../services/cities';
import Modal from '.././components/Modal'

const base = 'login';

@inject('UserStore')
@observer
class LoginComponent extends Component {
    constructor(props) {
        super(props);
        this.state = { selectedCity: '',
            showOtpModal: false,
            showOtpErrorModal: false,
            otp: '',
            invalidOtp: false,
            username: '',
            isEmail: false,
            password: ''
        };
    }

    handleUserEmail = (value, name) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isEmail = emailRegex.test(value);

        this.setState({
            [name]: value,
            isEmail,
            password: isEmail ? this.state.password : ''
        });
    }

    handleUserDetails = (value, name) => {
        this.setState((state) => ({
            [name]: value,
        }));
    }

    componentDidMount() {
        if (getCity()) {
            this.setState({ selectedCity: getCity() });
        }
    }

    handleSelectCity = (city, cb) => {
        this.props.UserStore.selectedHub = '';
        this.setState({ selectedCity: city });
        cb(city);
    };

    renderHubSelector = () => {
        const { UserStore } = this.props;
        const hubsInCurrentCity = UserStore.hubsList.filter(
            (hub) => hub.city === this.state.selectedCity
        );

        return (
            <div className={`${base}__hub-selector-container`}>
                <Select
                    value={UserStore.selectedHub}
                    autoFocus
                    required
                    options={hubsInCurrentCity}
                    style={{ width: '523px', marginBottom: '10px' }}
                    name="hubs"
                    onChange={UserStore.setSelectedHub}
                    placeholder="Select Outlet"
                />
            </div>
        );
    };

    handleLogin = async (e) => {
        const { UserStore } = this.props;
        e.preventDefault();
        let formData = new FormData();
        formData.append('user_type', 'Employee');
        formData.append('twoFactorAuthentication', true);
        for (const key of Object.keys(this.state)) {
            formData.append(key, this.state[key]);
        }
        const loginResponse = await UserStore.login(formData);
        
        if (loginResponse.requiresOtp) {
            if (loginResponse.error) {
                this.setState({ showOtpErrorModal: true });
            } else {
                this.setState({ showOtpModal: true });
            }
        }
    };

    handleOtpSubmit = async() => {
        const { UserStore } = this.props;
        const isValid = await UserStore.verifyOtp(this.state.otp);
        if (!isValid) {
            this.setState({invalidOtp : true})
        }
    };

    render() {
        const { UserStore } = this.props;
        const isLoading = this.props.loading;
        const title = this.props.title;

        return (
            <div className={`${base}`}>
                {isLoading && <Loading />}
                <div className="container display--flex flex-column justify--center">
                    <div className="section-area text--left">
                        {/* <WhiteLogoWhiteLeaf /> */}
                        <WhitePopLogo />
                        <div className="display--flex">
                            <p
                                className="text--white"
                                style={{ marginBottom: 0 }}
                            >
                                {title}
                            </p>
                            <div className={`margin--left dropdown`}>
                                <CountrySelector
                                    render={(city, cities, changeCity) => (
                                        <Fragment>
                                            <div className="display--flex ">
                                                <p
                                                    className="text--white"
                                                    style={{
                                                        marginRight: '10px',
                                                    }}
                                                >
                                                    {city === 'Kuala_Lumpur'
                                                        ? 'Kuala Lumpur'
                                                        : 'Bangkok'}
                                                </p>
                                                <button
                                                    className="bg--none"
                                                    style={{
                                                        padding: '12px 0',
                                                    }}
                                                >
                                                    <svg
                                                        className="iconmoon-icon center--vertical"
                                                        style={{
                                                            fill: 'white',
                                                        }}
                                                    >
                                                        <use xlinkHref="#icon-chevron-thin-down" />
                                                    </svg>
                                                </button>
                                            </div>
                                            <div className="list--none dropdown-content">
                                                {Object.keys(cities).map(
                                                    (city) => (
                                                        <a
                                                            key={city}
                                                            className="cursor-pointer"
                                                            onClick={() =>
                                                                this.handleSelectCity(
                                                                    city,
                                                                    changeCity
                                                                )
                                                            }
                                                        >
                                                            <p>
                                                                {cities[city]}
                                                            </p>
                                                        </a>
                                                    )
                                                )}
                                            </div>
                                        </Fragment>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    {UserStore.hubsList.length > 0 && this.renderHubSelector()}
                    <form onSubmit={this.handleLogin}>
                        <div className="section-area display--flex flex-column">
                            <input
                                className="border--rounded border--solid"
                                style={{ marginBottom: '20px' }}
                                required
                                name="username"
                                type="text"
                                value={this.props.value}
                                onChange={(e) =>
                                    this.handleUserEmail(
                                        e.target.value,
                                        e.target.name
                                    )
                                }
                                placeholder="Username"
                            ></input>
                            {this.state.isEmail && (
                                <input
                                    className="border--rounded border--solid"
                                    required
                                    id="password"
                                    name="password"
                                    type="password"
                                    value={this.props.value}
                                    onChange={(e) =>
                                        this.handleUserDetails(
                                            e.target.value,
                                            e.target.name
                                        )
                                    }
                                    placeholder="Password"
                                ></input>
                            )}
                        </div>
                        <div className="text--left section-area">
                            <button
                                className="text--white border--rounded"
                                disabled={!UserStore.selectedHub}
                            >
                                Log In
                            </button>
                        </div>
                    </form>
                    {this.state.showOtpModal && (
                        <Fragment>
                            <Modal height={'30vh'}>
                                <h1>Enter OTP</h1>
                                <input
                                    style={{margin: '25px 0'}}
                                    placeholder="OTP"
                                    value={this.state.otp}
                                    onChange={(e) => this.setState({ otp: e.target.value })}
                                />
                                {this.state.invalidOtp && (<span>Invalid Otp</span>)}
                                <div className={`${base}__confirmation_btns`}>
                                    <button
                                        className="btn btn-secondary ms flex-1"
                                        onClick={() => {
                                            this.setState({
                                                showOtpModal: false,
                                            });
                                        }}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="btn btn-primary ms flex-1"
                                        onClick={this.handleOtpSubmit}
                                    >
                                        Confirm
                                    </button>
                                </div>
                            </Modal>
                        </Fragment>
                    )}

                    {this.state.showOtpErrorModal && (
                        <Fragment>
                            <Modal height={'15vh'}>
                                <h1>Error</h1>
                                <h4>Network error. Please login again!</h4>
                            </Modal>
                        </Fragment>
                    )}
                </div>
            </div>
        );
    }
}

export default LoginComponent;
