import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import InstaServiceProductionCategory from './InstaServiceProductionCategory';

const base = 'insta-service';

@inject('ProductionStore', 'UserStore')
@observer
class InstaServiceProduction extends Component {
    render() {
        const {
            UserStore: {
                isCurrentPageFullScreen
            },
            instaServiceProductions
        } = this.props;

        const groupedByCategory = instaServiceProductions.reduce((acc, stock) => {
            stock.requests.forEach((request) => {
                if (request.status == "COMPLETED") {
                    return;
                }

                const { category } = request;
                
                if (!acc[category]) {
                    acc[category] = {
                        id: stock.id,
                        items: [],
                        created_date: stock.created_date
                    };
                }
        
                acc[category].items.push({
                    ...request,
                    id: stock.id
                });
        
                if (new Date(stock.created_date) < new Date(acc[category].created_date)) {
                    acc[category].created_date = stock.created_date;
                }
            });
            return acc;
        }, {});
        
        const categories = Object.keys(groupedByCategory).map(category => ({
            id: groupedByCategory[category].id,
            category,
            created_date: groupedByCategory[category].created_date,
            items: groupedByCategory[category].items.sort((a, b) => b.quantity - a.quantity)
        })).sort((a, b) => b.items.length - a.items.length); 

        return (
            <div>
                <div
                    className={`${base}__card_header top-up`}
                >
                    <div
                        className='top-up'
                    >
                        TOP UP NOW
                    </div>
                </div>
                <div 
                    className={`${
                        isCurrentPageFullScreen ? 'insta-service__card_container_full-screen' : 'insta-service__card_container'
                    }`}
                >
                    {categories &&
                    categories.length > 0 &&
                    categories.map((category) => (
                        <InstaServiceProductionCategory
                            category={category}
                            key={`${category.category}`}
                            isCurrentStock={false}
                        />
                    ))}
                </div>
            </div>
        );
    }
}

export default InstaServiceProduction;

