import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import SwitchButton from 'lyef-switch-button';

import Modal from '../Modal';

const base = 'inventory-adjustment';

@inject('InventoryStore')
@observer
class SKURowAdjustmentUpdate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSKUAdjustmentModal: false,
            showToUnholdConfirmationModal: false,
            showMarketingActivityModal: false,
            inputNumber: null,
            marketingInputNumber: '',
            selectedReasons: [],
        };
    }

    getTotalUnsoldMeals = (sku) => {
        const {
            InventoryStore: { productionBatches,
                              alwaysAvailableSkus },
        } = this.props;
        if (alwaysAvailableSkus[sku]){
            return "Unlimited";
        }
        const { batches } = productionBatches[sku];
        return batches.reduce((acc, batch) => acc + batch.quantity_left, 0);
    };

    isMenuEligibleAtOutlet = (sku) => {
        const {
            InventoryStore: { productionBatches }
        } = this.props;

        const { batches } = productionBatches[sku];
        if (!batches) {
            return true;
        }
        return batches[0].eligible_at_outlet;
    };

    handleSetCurrentSku = (sku) => {
        const {
            InventoryStore: { handleSetCurrentSku, handleComponentForCurrentMenuItemSku },
        } = this.props;
        handleComponentForCurrentMenuItemSku(sku);

        handleSetCurrentSku(sku);
    };
    handleInputNumberChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    };

    handleSKUToggle = () => {
        const {
            sku,
            InventoryStore: { availabilityOfSkus },
        } = this.props;
        const skuSalesObject = availabilityOfSkus[sku];
        if (skuSalesObject.available_for_sale_quantity != null) {
            this.setState({ showToUnholdConfirmationModal: true });
        } else {
            this.setState({ showSKUAdjustmentModal: true });
        }
    };

    handleUnholdMealConfirm = () => {
        const {
            sku,
            itemType,
            InventoryStore: { handleUpdateSaleAvailablity, availabilityOfSkus },
        } = this.props;
        const skuSalesObject = availabilityOfSkus[sku];

        const body = {
            available_for_sale_quantity: null,
            enabled: false,
            menu_item_id: skuSalesObject.menu_item_id,
            reasons: [],
        };

        handleUpdateSaleAvailablity(body, itemType);
        this.setState({ showToUnholdConfirmationModal: false });
    };

    handleSetSKUReason = (reason) => {
        if (!this.state.selectedReasons.includes(reason)) {
            this.setState((prevState) => ({
                selectedReasons: [...prevState.selectedReasons, reason],
            }));
        }
    };

    handleRemoveReason = (reason) => {
        if (this.state.selectedReasons.includes(reason)) {
            this.setState((prevState) => ({
                selectedReasons: prevState.selectedReasons.filter(
                    (selectedReason) => reason !== selectedReason
                ),
            }));
        }
    };

    updateSKUSalesAvailability = () => {
        const {
            sku,
            itemType,
            InventoryStore: { handleUpdateSaleAvailablity, availabilityOfSkus },
        } = this.props;
        const skuSalesObject = availabilityOfSkus[sku];
        if (skuSalesObject.always_available === true){
            this.state.inputNumber = 0;
        }

        const body = {
            available_for_sale_quantity: this.state.inputNumber,
            enabled: true,
            menu_item_id: skuSalesObject.menu_item_id,
            reasons: this.state.selectedReasons,
        };

        handleUpdateSaleAvailablity(body, itemType);
        this.setState({ showSKUAdjustmentModal: false });
    };

    handleOpenMarketingActivityModal = () => {
        this.setState({ showMarketingActivityModal: true });
    };

    handleCloseMarketingActivityModal = () => {
        this.setState({ showMarketingActivityModal: false, marketingInputNumber: '' });
    };

    handleMarketingInputChange = (e) => {
        const {
            sku,
            InventoryStore: { alwaysAvailableSkus },
        } = this.props;

        const { value } = e.target;
        let maximum = 100;
        if (!alwaysAvailableSkus[sku]) {
            const totalUnsold = this.getTotalUnsoldMeals(sku);
            maximum = Math.min(100, totalUnsold);
        }
        if (value === '' || (Number(value) >= 1 && Number(value) <= maximum)) {
            this.setState({ marketingInputNumber: value });
        }
    };

    handleMarketingActivityConfirm = () => {
        const { sku, InventoryStore: { handleDeductMarketingActivity } } = this.props;

        let request = {
            menuItemSku: sku,
            reason: "LSM_SAMPLING",
            delta: -this.state.marketingInputNumber
        };

        handleDeductMarketingActivity(request);

        this.setState({ showMarketingActivityModal: false, marketingInputNumber: '' });
    };

    render() {
        const {
            sku,
            itemType,
            InventoryStore: { availabilityOfSkus, skuSalesUnavailableReasons, alwaysAvailableSkus },
        } = this.props;
        const {
            showSKUAdjustmentModal,
            showToUnholdConfirmationModal,
            showMarketingActivityModal,
            inputNumber,
            marketingInputNumber,
            selectedReasons,
        } = this.state;

        const total_unsold_meals = this.getTotalUnsoldMeals(sku);
        const isEligibleAtOutlet = this.isMenuEligibleAtOutlet(sku);
        const skuSalesObject = availabilityOfSkus[sku];
        const reasonsToShowInDropdown = skuSalesUnavailableReasons.filter(
            (reason) => !selectedReasons.includes(reason)
        ); //only show those reasons in dropdown which are not selected by user.

        // Determine maximum allowed deduction for marketing activity.
        let marketingMaximumDeduction = 100;
        if (!alwaysAvailableSkus[sku]) {
            marketingMaximumDeduction = Math.min(100, total_unsold_meals);
        }

        return (
            <Fragment>
                {skuSalesObject && (
                    <div className="row col-xs-12" key={sku} style={{paddingBottom: '9px', paddingTop: '9px'}}>
                        <div className="col-xs-4" style={{paddingLeft: '5%'}}>
                            <div
                                key={sku}
                                className={`${base}__sku_button selected`}
                                onClick={alwaysAvailableSkus[sku] != null ? undefined : () => this.handleSetCurrentSku(sku)}
                            >
                                {sku}
                            </div>
                        </div>
                        <div className="col-xs-2" style={{height: '50px', lineHeight: '50px'}}>{`${total_unsold_meals} ${itemType === 'Item' ? 'Meals' : 'Units'}`}</div>
                        <div className={`${base}__sku_toggle_sell_quantity col-xs-2`} style={{height: '50px', lineHeight: '50px'}}>
                            <SwitchButton
                                id={`switch_${sku}`}
                                isChecked={
                                    skuSalesObject.available_for_sale_quantity !== null
                                }
                                action={() => this.handleSKUToggle()}
                                disabled={false}
                            />
                            {skuSalesObject.available_for_sale_quantity && (
                                <p>
                                    {skuSalesObject.available_for_sale_quantity}
                                </p>
                            )}
                        </div>
                        {itemType === 'Item' && (
                            <div className={`col-xs-2 ${base}__marketing_activity`}>
                                <button
                                    className="btn"
                                    onClick={this.handleOpenMarketingActivityModal}
                                >
                                    <svg className="icon-plus" style={{ height: '20px', width: '20px' }}>
                                        <use xlinkHref="#icon-plus" />
                                    </svg>
                                </button>
                            </div>
                        )}
                        <div className={`${base}__highlight_text ${base}__text_align_left text--bold fs--small-medium col-xs-2`}>
                            {!isEligibleAtOutlet && (
                                <span>Not available for this outlet</span>
                            )}
                        </div>

                    </div>
                )}


                {showSKUAdjustmentModal && (
                    <Modal width={'500px'} height={'600px'}>
                        <div className={`${base}__sku_meal_availability_modal`}>
                            <p className={`${base}__modal_title`}>
                                {itemType === 'Item' ? sku.split('_')[0] : sku}
                            </p>
                            <p className={`${base}__modal_title mbs`}>
                                {`${total_unsold_meals} Unsold Stock `}
                            </p>

                            <div
                                className={`${base}__availability_input_number`}
                            >
                                <label>Available for sale</label>
                                <input
                                    className="input_number"
                                    type="number"
                                    name="inputNumber"
                                    value={alwaysAvailableSkus[sku] != null ? 0 : inputNumber}
                                    min={0}
                                    onChange={this.handleInputNumberChange}
                                />
                            </div>
                            <div
                                className={`${base}__meal_availability_inputs`}
                            >
                                <div
                                    className={`${base}__availability_dropdown_container`}
                                >
                                    <p className="reason_text">Reason</p>
                                    <div className={`${base}__reasons`}>
                                        {selectedReasons.map(
                                            (selectedReason) => (
                                                <div
                                                    className={`${base}__selected_reason`}
                                                >
                                                    <p>{selectedReason}</p>
                                                    <svg
                                                        className={`${base}__reason_remove_btn`}
                                                        onClick={() =>
                                                            this.handleRemoveReason(
                                                                selectedReason
                                                            )
                                                        }
                                                        width="15"
                                                        height="14"
                                                        viewBox="0 0 15 14"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <rect
                                                            x="1.45337"
                                                            y="\0.0144653"
                                                            width="17.8442"
                                                            height="1.3587"
                                                            transform="rotate(45 1.45337 0.0144653)"
                                                            fill="white"
                                                        />
                                                        <rect
                                                            width="17.8442"
                                                            height="1.3587"
                                                            transform="matrix(-0.707107 0.707107 0.707107 0.707107 13.1104 0.0144653)"
                                                            fill="white"
                                                        />
                                                    </svg>
                                                </div>
                                            )
                                        )}
                                        <div className={`${base}__dropdown `}>
                                            {reasonsToShowInDropdown[0]}
                                            <svg
                                                fill="#fff"
                                                className="iconmoon-icon"
                                            >
                                                <use
                                                    xlinkHref={`#icon-caret-down`}
                                                />
                                            </svg>
                                            <div
                                                className={`dropdown-content reasons`}
                                            >
                                                {reasonsToShowInDropdown.map(
                                                    (reason, i) => (
                                                        <a
                                                            key={i}
                                                            className="cursor-pointer"
                                                            onClick={() =>
                                                                this.handleSetSKUReason(
                                                                    reason
                                                                )
                                                            }
                                                        >
                                                            {reason}
                                                        </a>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`${base}__meal_availability_btns`}>
                                <button
                                    className="btn btn-secondary ms flex-1"
                                    onClick={() => {
                                        this.setState({
                                            showSKUAdjustmentModal: false,
                                        });
                                    }}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="btn btn-primary ms flex-1"
                                    onClick={() =>
                                        this.updateSKUSalesAvailability()
                                    }
                                >
                                    Update
                                </button>
                            </div>
                        </div>
                    </Modal>
                )}

                {showToUnholdConfirmationModal && (
                    <Modal width={'400px'}>
                        <div>
                            <p className={`${base}__modal_title`}>
                                {`Enable `}
                                {itemType === 'Item'
                                    ? sku.split('_')[0]
                                    : sku}{' '}
                                with total unsold meals {total_unsold_meals} for
                                sale
                            </p>
                            <div className={`${base}__meal_availability_btns`}>
                                <button
                                    className="btn btn-secondary ms flex-1"
                                    onClick={() => {
                                        this.setState({
                                            showToUnholdConfirmationModal: false,
                                        });
                                    }}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="btn btn-primary ms flex-1"
                                    onClick={() => this.handleUnholdMealConfirm()}
                                >
                                    Confirm
                                </button>
                            </div>
                        </div>
                    </Modal>
                )}

                {itemType === 'Item' && showMarketingActivityModal && (
                    <Modal width={'500px'}>
                        <div className={`${base}__marketing_modal`}>
                            <p className={`${base}__modal_title`}>
                                {sku}
                            </p>
                            <p className={`${base}__modal_title mbs`}>
                                How many meals do you want to deduct from inventory?
                            </p>
                            <p className={`${base}__modal_desc`}>
                                Make sure you have Territory Manager approval. If unauthorized, the value of the meals will be deducted from your salary.
                            </p>

                            <div className={`${base}__marketing_input`}>
                                <label>Deduct Meals</label>
                                <input
                                    className="marketing_number"
                                    type="number"
                                    name="marketingInputNumber"
                                    value={marketingInputNumber}
                                    min={1}
                                    max={marketingMaximumDeduction}
                                    onChange={this.handleMarketingInputChange}
                                />
                                <p>Maximum allowed: {marketingMaximumDeduction}</p>
                            </div>
                            <div className={`${base}__marketing_modal_btns`}>
                                <button
                                    className="btn btn-secondary ms flex-1"
                                    onClick={this.handleCloseMarketingActivityModal}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="btn btn-primary ms flex-1"
                                    onClick={this.handleMarketingActivityConfirm}
                                >
                                    Confirm
                                </button>
                            </div>
                        </div>
                    </Modal>
                )}
            </Fragment>
        );
    }
}

export default SKURowAdjustmentUpdate;
