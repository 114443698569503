import { observable, action, computed, toJS } from 'mobx';
import * as api from '../services/api';
import UserStore from './common/UserStore';
import { saveToLocalStorage, getFromLocalStorage } from '../services/utils';
import moment from 'moment';
import ScanningStore from './common/ScanningStore';
import { getCashierScreenResponse } from '../services/api';

class LiveDispatchStore {
    @observable date = moment().format('DD-MM-YYYY');
    @observable loading = true;
    @observable routes = [];
    @observable dineInOrders = [];
    @observable pickUpOrders = [];
    @observable inStoreOrders = [];
    @observable bags = [];
    @observable instaServiceProductions = [];
    @observable currentWarmerStocks = [];

    //variables for scanning barcode
    @observable scannedItem = null;
    @observable scannedItemSKU = '';
    @observable scannedItemError = '';
    @observable showScannedItemOnLiveDispatch = false;
    @observable itemScanLoading = false;

    //variables for image upload functionality
    @observable isImageUploading = false;
    @observable showImage = false;
    @observable itemImage = null;
    @observable originalItemImageLink = null;

    @observable remakeLoading = false;
    @observable SKURemakeErrorMsg = '';
    @observable closedAggregatorStores = [];
    @observable recentlyClosedAggregatorStores = [];
    @observable truckAlerts = [];
    @observable recentTruckAlerts = [];
    @observable thirdPartyDeliveryErrorMessage = '';
    @observable orderNumber = [];
    @observable orderMarkedDoneErrorMessage = null;

    routesLastModified = null;
    instaServiceLastModified = null;

    // bag status
    CANCELLED = 'Cancelled';

    // item status
    ON_ROUTE = 'ON_ROUTE';
    IN_WARMER = 'IN_WARMER';
    IN_PRODUCTION = 'IN_PRODUCTION';

    @action resetProductionLastModified = () => {
        this.routesLastModified = null;
    };

    @action setItemImage = (image) => {
        if (image) {
            this.itemImage = image;
        }
    };

    errorCallbackRemakeAPI = (errorObject) => {
        if (errorObject.response) {
            errorObject.response.json().then((body) => {
                if (body.message) {
                    this.SKURemakeErrorMsg = body.message;
                }
            });
        }
    };
    errorCallbackSKUScanAPI = (errorObject) => {
        if (errorObject.response) {
            errorObject.response.json().then((body) => {
                if (body.message) {
                    this.scannedItemError = body.message;
                }
            });
        }
    };

    @action handleUploadImage = (image, item) => {
        this.isImageUploading = true;
        api.uploadItemImage(image, item)
            .then((res) => {
                this.showImage = true;
                setTimeout(() => {
                    this.showImage = false;
                }, 5000);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                this.isImageUploading = false;
            });
    };

    @action handleRemakeSKUItem = (item, callback) => {
        this.remakeLoading = true;
        let status;
        api.handleRemakeSKUItem(item, this.errorCallbackRemakeAPI)
            .then((res) => {
                status = 'SUCCESS';
            })
            .catch((err) => {
                console.log(err);
                status = 'FAILED';
            })
            .finally(() => {
                this.remakeLoading = false;
                callback && callback(status);
            });
    };

    @action handleReprintSKUItem = (item, callback) => {
        let status;
        api.handleReprintSKUItem(item, this.errorCallbackRemakeAPI)
            .then((res) => {
                status = 'SUCCESS';
            })
            .catch((err) => {
                console.log(err);
                status = 'FAILED';
            })
            .finally(() => {
                callback && callback(status);
            });
    };

    @action handledScannedItem = (
        todayMenuId,
        incrementalNumber,
        pickUpNumber = null
    ) => {
        this.showScannedItemOnLiveDispatch = true;
        this.itemScanLoading = true;

        api.handledScannedItem(
            todayMenuId,
            this.date,
            incrementalNumber,
            pickUpNumber,
            this.errorCallbackSKUScanAPI
        )
            .then((res) => {
                this.scannedItem = res;
                this.scannedItemError = '';
                this.originalItemImageLink = res.dispatch_scan_example_image;
                if (this.itemImage) {
                    const imageToBeUploaded = String(
                        this.itemImage.split('base64,')[1]
                    ); //removing the pre-string from the image
                    this.handleUploadImage(imageToBeUploaded, res.item);
                }
                if (res.sound_file) {
                    const audio = new Audio(
                        `${process.env.PUBLIC_URL}/${res.sound_file}`
                    );
                    audio.play()
                        .catch(error => {
                            console.log("Error playing sound alert");
                        });
                }
            })
            .catch((err) => {
                this.scannedItem = null;
                console.log(err);
            })
            .finally(() => {
                this.itemScanLoading = false;

                setTimeout(() => {
                    this.showScannedItemOnLiveDispatch = false;
                }, 5 * 1000);
            });
    };

    @action handleScanPopup = () => {
        this.showScannedItemOnLiveDispatch = false;
    }

    @action handledScannedWarmerItem = (
        productionId,
        sku,
        quantity
    ) => {
        this.showScannedItemOnLiveDispatch = true;
        this.itemScanLoading = true;

        api.handledScannedWarmerItem(
            productionId,
            sku,
            quantity,
            this.errorCallbackSKUScanAPI
        )
            .then((res) => {
                if (!res.items) {
                    this.scannedItemError = 'Sticker already scanned';
                    this.scannedItem = null;
                } else {
                    this.scannedItem = res;
                    this.scannedItem.isWarmerItem = true;
                    this.scannedItemSKU = sku;
                    this.scannedItemError = '';
                    this.originalItemImageLink = res.dispatch_scan_example_image;
                    if (this.itemImage) {
                        this.showImage = true;
                        setTimeout(() => {
                            this.showImage = false;
                        }, 5000);
                    }
                }
            })
            .catch((err) => {
                this.scannedItem = null;
                console.log(err);
            })
            .finally(() => {
                this.itemScanLoading = false;

                setTimeout(() => {
                    this.showScannedItemOnLiveDispatch = false;
                }, 5 * 1000);
            });
    };

    @action handledScannedBag = (pickUpNumber) => {
        this.showScannedItemOnLiveDispatch = true;
        this.itemScanLoading = true;
        const bagImage = this.itemImage ? String(this.itemImage.split('base64,')[1]) : null;

        api.handledScannedBagSticker(pickUpNumber, bagImage)
            .then((res) => {
                this.scannedItem = res;
                this.scannedItem.bag = true;
            })
            .catch((err) => {
                console.log(err);
                this.itemImage = null;
            })
            .finally(() => {
                this.itemScanLoading = false;

                setTimeout(() => {
                    this.showScannedItemOnLiveDispatch = false;
                }, 5 * 1000);
            });
    };

    @action sendRouteDispatch = (routeid) => {
        this.thirdPartyDeliveryErrorMessage = false;
        this.loading = true;
        //Third party delivery route id container Route - orderId
        if (routeid.includes("Route -")) {
            api.sendRetryDelivery(routeid.slice(routeid.indexOf("Route - ") + 8))
            .then((res) => {
                if (res) {
                    const { responseBody } = res;
                    responseBody.results.map((o) => {
                        if (o.error_message) {
                            this.thirdPartyDeliveryErrorMessage = true;
                            this.orderNumber.push(o.order_numbers);
                        }
                    });
                }
                this.loading = false;
            })
            .catch((err) => {
                UserStore.message = err.message;
            });
            return;
        }

        api.sendRoute(routeid)
            .then((res) => {
                if (res) {
                    const { lastModified, responseBody } = res;
                    responseBody.results.map((o) => {
                        if (o.error_message) {
                            this.thirdPartyDeliveryErrorMessage = true;
                            this.orderNumber.push(o.order_numbers);
                        }
                    });
                }
                this.loading = false;
            })
            .catch((err) => {
                UserStore.message = err.message;
            });
    };

    @action handleGetDispatches = (forceLatest = false, date = this.date) => {
        this.date = date;
        let ifModifiedSince = null;
        if (!forceLatest) {
            ifModifiedSince = this.routesLastModified;
        }

        api.getRoutes(this.date, ifModifiedSince)
            .then((res) => {
                if (res.modified) {
                    console.log('res.modified');
                    const { lastModified, responseBody } = res;
                    const { bags, routes } = responseBody;
                    this.routesLastModified = lastModified;

                    const sortedBags =
                        bags &&
                        bags.sort((a, b) => a.bag_number - b.bag_number);
                    this.bags = sortedBags ? [...sortedBags] : [];

                    const pickUpOrders = this.bags
                        .filter((bag) => bag.customer_self_pickup_at_outlet)
                        .map((bag) => {
                            const {
                                amount_due,
                                delivery_id,
                                delivery_time,
                                order_number,
                                payment_method,
                                pre_tax_net_total,
                            } = bag;
                            return {
                                isPickUpOrder: true,
                                amount_due,
                                order_number,
                                delivery_id,
                                start_date: delivery_time,
                                payment_method,
                                pre_tax_net_total,
                                bags: [bag],
                            };
                        });

                    const allOrders = routes.concat(pickUpOrders);

                    const sortedRoutes = allOrders
                        .filter(
                            (route) =>
                                route.bags.length &&
                                !route.bags.every(
                                    (bag) => bag.status === this.CANCELLED
                                )
                        )
                        .sort(
                            (a, b) =>
                                a.start_date - b.start_date ||
                                a.dispatch_no - b.dispatch_no ||
                                a.route_no - b.route_no
                        );
                    this.routes = sortedRoutes ? [...sortedRoutes] : [];
                }
                this.loading = false;
            })
            .catch((err) => {
                UserStore.message = err.message;
            });
    };

    @action handleGetDineInOrders = (forceLatest = false, date = this.date) => {
        this.date = date;
        let ifModifiedSince = null;
        if (!forceLatest) {
            ifModifiedSince = this.routesLastModified;
        }

        api.getDineInOrders(this.date, ifModifiedSince)
            .then((res) => {
                if (res.modified) {
                    const { lastModified, responseBody } = res;
                    const { list: dineInOrders, message } = responseBody;
                    this.routesLastModified = lastModified;
                    const newDineInOrders = dineInOrders
                        ? dineInOrders.filter(
                              (order) =>
                                  order.status !== 'Completed' &&
                                  order.items &&
                                  order.items.length > 0
                          )
                        : [];

                    const sortedDineInOrders =
                        newDineInOrders &&
                        newDineInOrders.sort(
                            (a, b) => a.order_date - b.order_date
                        );

                    this.dineInOrders = sortedDineInOrders
                        ? [...sortedDineInOrders]
                        : [];
                }
                this.loading = false;
            })
            .catch((err) => {
                UserStore.message = err.message;
            });
    };

    @action handleGetAggregatorStoreStatus = () => {
        this.recentlyClosedAggregatorStores = [];
        api.getAggregatorStoreStatus()
            .then((response) => {
                response.forEach((entry) => {
                    if (
                        entry.open === false &&
                        this.closedAggregatorStores.indexOf(
                            entry.sale_platform_name
                        ) === -1
                    ) {
                        this.closedAggregatorStores.push(
                            entry.sale_platform_name
                        );
                        this.recentlyClosedAggregatorStores.push(
                            entry.sale_platform_name
                        );
                    }
                    if (
                        entry.open === true &&
                        this.closedAggregatorStores.indexOf(
                            entry.sale_platform_name
                        ) > -1
                    ) {
                        this.closedAggregatorStores.splice(
                            this.closedAggregatorStores.indexOf(
                                entry.sale_platform_name
                            ),
                            1
                        );
                    }
                });
            })
            .catch((err) => console.log(err));
    };

    @action handleGetTruckAlerts = () => {
        api.getTruckStatus()
            .then((response) => {
                response.forEach((alert) => {
                    const TRUCK_ARRIVED = 'Truck has arrived';
                    const TRUCK_ARRIVING_IN_10_MINUTES = 'Truck arriving in 10 minutes';
                    if (alert.status === 'ARRIVED') {
                        alert.status = TRUCK_ARRIVED
                    } else if (alert.status === 'ARRIVING_IN_10_MINUTES') {
                        alert.status = TRUCK_ARRIVING_IN_10_MINUTES
                    }
                    this.recentTruckAlerts = [];
                    // Add to `recentTruckAlerts` only if not acknowledged
                    if (!alert.acknowledged) {
                        this.recentTruckAlerts.push(alert);
                    }
                })
                this.truckAlerts = response;
            })
        .catch((err) => console.log(err));
    };

    @action handleOpenStore = (store) => {
        api.openStore(store)
            .then((response) => {
                this.closedAggregatorStores.splice(
                    this.closedAggregatorStores.indexOf(store),
                    1
                );
                this.recentlyClosedAggregatorStores.splice(
                    this.recentlyClosedAggregatorStores.indexOf(store),
                    1
                );
            })
            .catch((err) => {
                UserStore.message =
                    'Store not opened successfully. Please try again';
                this.recentlyClosedAggregatorStores = [];
            });
    };

    @action handleAcknowledgeTruckAlert = (hub_code) => {
        api.acknowledgeTruckAlert(hub_code)
            .then((response) => {
                if (response) {
                    this.truckAlerts = [];
                } else {
                    UserStore.message =
                        'Truck alert not acknowledged successfully. Please try again';
                }
                this.recentTruckAlerts = [];
            })
            .catch((err) => {
                UserStore.message =
                    'Truck alert not acknowledged successfully. Please try again';
                this.recentTruckAlerts = [];
            });
    };

    @action handleGetCashierScreenResponse = (
        forceLatest = false,
        date = this.date
    ) => {
        this.date = date;
        let ifModifiedSince = null;
        if (!forceLatest) {
            ifModifiedSince = this.routesLastModified;
        }

        api.getCashierScreenResponse(this.date, ifModifiedSince)
            .then((res) => {
                if (res.modified) {
                    const { lastModified, responseBody } = res;
                    const { pick_up_orders, in_store_orders } = responseBody;
                    this.routesLastModified = lastModified;

                    const sortedBags =
                        pick_up_orders &&
                        pick_up_orders.sort(
                            (a, b) => a.bag_number - b.bag_number
                        );
                    this.pickUpOrders = sortedBags ? [...sortedBags] : [];

                    // this.pickUpOrders = this.pickUpOrders.map((bag) => {
                    //     // const {
                    //     // //     amount_due,
                    //     // //     delivery_id,
                    //     // //     delivery_time,
                    //     // //     order_number,
                    //     // //     payment_method,
                    //     // //     pre_tax_net_total,
                    //     // // } = bag;
                    //     return {
                    //         bag,
                    //         start_date: delivery_time,
                    //     };
                    // });
                    const newInStoreOrders = in_store_orders
                        ? in_store_orders.filter(
                              (order) => order.items && order.items.length > 0
                          )
                        : [];

                    const sortedInStoreOrders =
                        newInStoreOrders &&
                        newInStoreOrders.sort(
                            (a, b) => a.order_date - b.order_date
                        );

                    this.inStoreOrders = sortedInStoreOrders
                        ? [...sortedInStoreOrders]
                        : [];
                }
                this.loading = false;
            })
            .catch((err) => {
                UserStore.message = err.message;
            });
    };

    @action handleConfirmPickupOrder = (deliveryId) => {
        api.confirmPickupOrder(deliveryId)
        .catch((err) => console.log(err))
        .finally(() => {
            this.handleGetDispatches(true)
        });
    };

    @action handleConfirmDineInOrder = (deliveryId) => {
        api.confirmDineInOrder(deliveryId).catch((err) => console.log(err));
    };

    @action handleGetInstaServiceProduction = (forceLatest = false) => {
        let ifModifiedSince = null;
        if (!forceLatest) {
            ifModifiedSince = this.instaServiceLastModified;
        }

        api.getInstaServiceProduction(ifModifiedSince)
            .then((res) => {
                if (res.modified) {
                    const { lastModified, responseBody } = res;
                    this.instaServiceProductions = responseBody.list ? responseBody.list : [];
                    this.instaServiceLastModified = lastModified;
                    if (responseBody.current_stock) {
                        this.currentWarmerStocks = responseBody.current_stock;
                    }
                }
                this.loading = false;
            })
            .catch((err) => {
                UserStore.message = err.message;
            });
    };

    @action handleMarkOrderAsDone = (orderId, callback) => {
        let message;
        api.markOrderAsDone(orderId)
            .then((res) => {
                message = 'SUCCESS';
            })
            .catch((err) => {
                message = err.message;
            })
            .finally(() => {
                callback && callback(message);
            });
    }

    @action updateItemStatus = (lastScannedItem, inOutletOrders) => {
        let orders = [];
        if (inOutletOrders) {
            this.dineInOrders.filter((order) => (order.production_started_at && (order.sale_platform == "Pick Up" || order.sale_platform == "Dine In"))).map((dineInOrder) => (
                orders.push(dineInOrder)
            ));

            this.bags.filter((order) => (order.production_started_at && order.order_mode == "PICK_UP")).map((bag) => (
                orders.push(bag)
            ));
        }
        
        if (!inOutletOrders) {
            this.bags.filter((order) => order.production_started_at && order.order_mode != "PICK_UP").map((bag) => (
                orders.push(bag)
            ));

            this.dineInOrders.filter((order) => (order.production_started_at && (order.sale_platform != "Pick Up" && order.sale_platform != "Dine In"))).map((dineInOrder) => (
                orders.push(dineInOrder)
            ));
        }

        if (lastScannedItem) {
            const order = orders.find((order) => lastScannedItem.order_number === order.order_number);
            if (order) {
                let item = order.items.find((item) => 
                    item.today_menu_id === lastScannedItem.today_menu_id &&
                    item.incremental_number === lastScannedItem.incremental_number &&
                    item.status === "IN_PRODUCTION" && lastScannedItem.status === "ON_ROUTE"
                );
                if (item) {
                    item.status = "ON_ROUTE";
                }
            }
        }
    }

    @action displayErrorResultFunc = (message) => {
        this.scannedItemError = message;
        this.scannedItem = null;
        this.showScannedItemOnLiveDispatch = true;
        setTimeout(() => {
            this.showScannedItemOnLiveDispatch = false;
        }, 5 * 1000);
    }
}

const store = new LiveDispatchStore();
export default store;
