import {action, computed, observable} from 'mobx';
import * as api from '../services/api';
import _ from 'lodash';
import UserStore from './common/UserStore';
import ComponentStockStore from './ComponentStockStore';
import moment from 'moment';
import InventoryConstants from "../components/inventory/InventoryConstants";

const INITIAL_MORNING_SHIFT = 'initial';
const END_OF_DAY_SHIFT = 'final';
const INITIAL_CHECK_COMPLETED = 'isInitialCheckCompleted';
const FINAL_CHECK_COMPLETED = 'isFinalCheckCompleted';

class InventoryStore {
    initialShiftStartTime = '00:01';
    initialShiftEndTime = '12:00';
    finalShiftStartTime = '21:00';
    finalShiftEndTime = '23:59';
    finalShiftStartTimeNextDay = '21:00';
    finalShiftEndTimeNextDay = '00:00';
    last_updated = null;
    componentInventoryRolledOutStores = ['MY_KL_TTDI'];
    @observable loading = false;
    @observable date = moment();
    @observable productionBatches = {};
    @observable componentStock = {};
    @observable shift = '';
    @observable currentSku = '';
    @observable itemType = 'Item';
    @observable itemTypes = ['Item', 'Addons', 'Combo'];
    @observable skuSalesUnavailableReasons = [];
    @observable availabilityOfSkus = {};
    @observable alwaysAvailableSkus = {};
    @observable componentListForMenuItem = [];
    @observable sortedObject = {};
    reasonsToShowInDropdown = [
        {value: 'EXPIRED', label: 'Expired'},
        {value: 'DAMAGED', label: 'Damaged'},
        {value: 'TESTING', label: 'Testing'},
        {value: 'MARKETING_HQ', label: 'Marketing (HQ)'},
        {value: 'LSM_SAMPLING', label: 'LSM Sampling'},
        {value: 'WRONG_COUNTING', label: 'Wrong counting'},
        {value: 'INTER_OUTLET_TRANSFER', label: 'Inter outlet transfer'},
        {value: 'OTHERS', label: 'Others'}
    ]

    @computed get skuList() {
        let skuList = this.productionBatches;
        Object.entries(this.alwaysAvailableSkus).forEach((sku) =>{
                skuList[sku[0]] =  sku[1]
        })
        return Object.keys(skuList);
    }

    @computed get totalSkus() {
        return this.skuList.length;
    }

    @computed get totalSkusChecked() {
        return this.skuList.reduce(
            (total, sku) => (this.isSkuChecked(sku) ? total + 1 : total),
            0
        );
    }

    @computed get skusByLetter() {
        return this.skuList.reduce((acc, sku) => {
            const letter = sku.charAt(0);
            acc[letter] = acc[letter] ? acc[letter].concat([sku]) : [sku];
            return acc;
        }, {});
    }

    @computed get skusByCategory() {
        const unsortedObject = this.putSkusInCategory
        if (this.itemType === 'Item') {
            return unsortedObject
        }
        //Show Snacks -> Desserts -> Drinks for addons
        if (Object.keys(unsortedObject).length > 0){
            return {"Snacks": unsortedObject['Snacks'] ? unsortedObject['Snacks'] : [], "Desserts": unsortedObject['Desserts'] ? unsortedObject['Desserts'] : [], "Drinks": unsortedObject['Drinks'] ? unsortedObject['Drinks'] : []}
        } else {
            return {}
        }
    }

    @computed get putSkusInCategory() {
        if (this.itemType === 'Item'){
            return this.skuList.reduce((acc, sku) => {
                acc["Meals"] = acc["Meals"] ? acc["Meals"].concat([sku]) : [sku];
            return acc;
            }, {});
        }
        return this.skuList.reduce((acc, sku) => {
            if (this.productionBatches[sku]['batches'] != null){
                const category = this.productionBatches[sku]['batches'][0]['menu_item_category'];
                acc[category] = acc[category] ? acc[category].concat([sku]) : [sku];
            } else {
                const category = this.alwaysAvailableSkus[sku].menu_item_category;
                acc[category] = acc[category] ? acc[category].concat([sku]) : [sku];
            }
            return acc;
        }, {});
    }


    @computed get skusByCategoryForLiveInventory() {
        const unsortedObject = this.putSkusInCategoryForLiveInventory
        //Show Snacks -> Desserts -> Drinks for addons
        if (Object.keys(unsortedObject).length > 0){
            return {"Meals": unsortedObject["Meals"], "Snacks": unsortedObject['Snacks'], "Desserts": unsortedObject['Desserts'], "Drinks": unsortedObject['Drinks']}
        } else {
            return {}
        }
    }

    @computed get putSkusInCategoryForLiveInventory() {
        return this.skus.reduce((acc, res) => {
            if (this.productionBatches[res.sku]['batches'] != null){
                const category = this.productionBatches[res.sku]['batches'][0]['menu_item_category'];
                if(category == null){
                    acc["Meals"] = acc["Meals"] ? acc["Meals"].concat([res]) : [res];
                } else {
                    acc[category] = acc[category] ? acc[category].concat([res]) : [res];
                }
            }
            return acc;
        }, {});
    }

    @computed get skus() {
        return this.skuList
            .reduce((acc, sku) => {
                if (this.productionBatches[sku]['batches']){
                    if (this.productionBatches[sku]['batches'][0]['always_available']){
                        acc.push({
                            sku: sku,
                            name: this.productionBatches[sku]['batches'][0]['menu_item_name'],
                            available_for_sale: this.productionBatches[sku]['batches'][0]['unavailable_reasons'] != null && 
                            this.productionBatches[sku]['batches'][0]['unavailable_reasons'].length == 0,                            not_on_hold: this.productionBatches[sku]['batches'][0]['unavailable_reasons'] != null && 
                            this.productionBatches[sku]['batches'][0]['unavailable_reasons'].indexOf("ON_HOLD") == -1,
                            in_stock: true,
                            in_daily_menu: this.productionBatches[sku]['batches'][0]['unavailable_reasons'] != null && 
                            this.productionBatches[sku]['batches'][0]['unavailable_reasons'].indexOf("NOT_IN_DAILY_MENU") == -1,
                            price_table_on: this.productionBatches[sku]['batches'][0]['unavailable_reasons'] != null && 
                            this.productionBatches[sku]['batches'][0]['unavailable_reasons'].indexOf("NOT_AVAILABLE_FOR_SALE_PLATFORM") == -1,
                            eligible_outlet: this.productionBatches[sku]['batches'][0]['unavailable_reasons'] != null && 
                            this.productionBatches[sku]['batches'][0]['unavailable_reasons'].indexOf("NOT_ELIGIBLE_AT_OUTLET") == -1,
                            quantity_left: "Unlimited",
                            virtual_stock: "Unlimited",
                            preorders: "NaN",
                            menuItemCategory: this.productionBatches[sku]['batches'][0]['menu_item_category'],
                            menu_item_ranking: this.productionBatches[sku]['batches'][0]['menu_item_ranking']
                        });
                    } else {
                        acc.push({
                            sku: sku,
                            name: this.productionBatches[sku]['batches'][0]['menu_item_name'],
                            quantity_left: this.productionBatches[sku][
                                'batches'
                                ].reduce((a, batch) => a + batch.quantity_left, 0),
                            virtual_stock: this.productionBatches[sku][
                                'batches'
                                ].reduce((a, batch) => a + batch.virtual_stock, 0),
                            preorders:
                                this.productionBatches[sku]['batches'].reduce(
                                    (a, batch) => a + batch.virtual_stock,
                                    0
                                ) -
                                this.productionBatches[sku]['batches'].reduce(
                                    (a, batch) => a + batch.quantity_left,
                                    0
                                ),
                                available_for_sale: this.productionBatches[sku]['batches'][0]['unavailable_reasons'] != null && 
                                this.productionBatches[sku]['batches'][0]['unavailable_reasons'].length == 0,
                                not_on_hold: this.productionBatches[sku]['batches'][0]['unavailable_reasons'] != null && 
                                this.productionBatches[sku]['batches'][0]['unavailable_reasons'].indexOf("ON_HOLD") == -1,
                                in_stock: this.productionBatches[sku]['batches'][0]['unavailable_reasons'] != null && 
                                this.productionBatches[sku]['batches'][0]['unavailable_reasons'].indexOf("OUT_OF_STOCK") == -1,
                                in_daily_menu: this.productionBatches[sku]['batches'][0]['unavailable_reasons'] != null && 
                                this.productionBatches[sku]['batches'][0]['unavailable_reasons'].indexOf("NOT_IN_DAILY_MENU") == -1,
                                price_table_on: this.productionBatches[sku]['batches'][0]['unavailable_reasons'] != null && 
                                this.productionBatches[sku]['batches'][0]['unavailable_reasons'].indexOf("NOT_AVAILABLE_FOR_SALE_PLATFORM") == -1,
                                eligible_outlet: this.productionBatches[sku]['batches'][0]['unavailable_reasons'] != null && 
                                this.productionBatches[sku]['batches'][0]['unavailable_reasons'].indexOf("NOT_ELIGIBLE_AT_OUTLET") == -1,
                                menuItemCategory: this.productionBatches[sku]['batches'][0]['menu_item_category'],
                                menu_item_ranking: this.productionBatches[sku]['batches'][0]['menu_item_ranking']
                        });
                    }
                } else {
                    //For always available items
                    acc.push({
                        sku: sku,
                        name: menu_item_name,
                        quantity_left: "Unlimited",
                        virtual_stock: "Unlimited",
                        preorders: "NaN",
                    });
                }
                return acc;
            }, [])
            .sort((a, b) => (a.menu_item_ranking - b.menu_item_ranking))
            .sort((a,b) => (b.eligible_outlet - a.eligible_outlet));
    }

    isSkuChecked = (sku) => {
        const batches = this.productionBatches[sku]['batches'];
        return batches.some((batch) => batch[FINAL_CHECK_COMPLETED]);
    };

    @computed get initialSku() {
        for (const sku in this.productionBatches) {
            const batches = this.productionBatches[sku]['batches'];
            if (batches.some((batch) => !batch[FINAL_CHECK_COMPLETED])) return sku;
        }
        return Object.keys(this.productionBatches)[0];
    }

    @action lastUpdated = (stock_check_tracker) => {
        const stockCheckTransferLog =
            stock_check_tracker &&
            stock_check_tracker.filter((entry) => {
                const { production, updated_at } = entry;
                const updatedAtHub = !production;
                return updatedAtHub && this.isUpdatedInTimeFrame(updated_at);
            });

        return (
            stockCheckTransferLog &&
            stockCheckTransferLog[stockCheckTransferLog.length - 1]
        );
    };

    @action handleSetCurrentSku = (sku) => {
        this.currentSku = sku;
        if (sku) this.handleGetVirtualStockForSkuBatches();
    };

    @action handleComponentForCurrentMenuItemSku = (sku) => {
        api.getComponentForCurrentMenuItemSku(sku)
            .then((res) => {
                this.componentListForMenuItem = res;
            })
            .catch((err) => {});
    };

    @action handleSetShift = (shift) => {
        this.shift = shift;
    };

    @action handleSetItemType = (type) => {
        this.itemType = type;
    };

    @action isCheckCompleted = (stock_check_tracker) => {

        return (
            stock_check_tracker &&
            stock_check_tracker.some((entry) => {
                const { production, updated_at } = entry;
                const updatedAtHub = !production;
                return updatedAtHub && this.isUpdatedInTimeFrame(updated_at);
            })
        );
    };

    isUpdatedInTimeFrame = (updated_at) => {
        const startTime = moment(InventoryConstants.FINAL_SHIFT_START_TIME, 'HH:mm');
        const endTime = moment(InventoryConstants.FINAL_SHIFT_END_TIME, 'HH:mm');
        const startTimeNextDay = moment(InventoryConstants.FINAL_SHIFT_START_TIME_NEXT_DAY, 'HH:mm');
        const endTimeNextDay = moment(InventoryConstants.FINAL_SHIFT_END_TIME_NEXT_DAY, 'HH:mm');
        let updatedInTimeframe
        let now = new Date();
        if (now.getHours() >= InventoryConstants.STOCK_CHECK_START_HOUR){
            updatedInTimeframe = moment(updated_at, 'x').isBetween(startTime, endTime)
        } else if(now.getHours() < InventoryConstants.STOCK_CHECK_END_HOUR){
            updatedInTimeframe = moment(updated_at, 'x').isBetween(startTime.subtract(1, 'days'), endTime.subtract(1, 'days')) ||
                moment(updated_at, 'x').isBetween(startTimeNextDay, endTimeNextDay);
        }
        return updatedInTimeframe;
    }

    @action handleUpdateSaleAvailablity = (body, itemType) => {
        api.updateSKUSaleAvailability(body)
            .then((res) => {
                this.handleGetSKUSalesAvailability(itemType);
            })
            .catch((err) => {});
    };
    @action handleGetSKUSalesAvailability = (itemType) => {
        if(!ComponentStockStore.showStockCount()){
            return
        }
        const date = this.date.format('DD-MM-YYYY');

        api.getSKUSalesAvailability(date, itemType)
            .then((res) => {
                let availabilityOfSkus = {};
                let alwaysAvailableSkus = {};
                res.items.forEach((item) => {
                    if (item.always_available === true){
                        alwaysAvailableSkus[item.sku] = {
                            available_for_sale_quantity: "Unlimited",
                            menu_item_id: item.menu_item_id,
                            always_available: item.always_available,
                            menu_item_category: item.menu_item_category
                        }
                    }
                    availabilityOfSkus[item.sku] = {
                        available_for_sale_quantity:
                            item.available_for_sale_quantity,
                        menu_item_id: item.menu_item_id,
                        always_available: item.always_available,
                        menu_item_category: item.menu_item_category
                    };
                });
                // availabilityOfSkus will be an object where key=sku and value of that key will be a further object with available_for_sale_quantity and menu_item_id field
                this.availabilityOfSkus = availabilityOfSkus;
                this.alwaysAvailableSkus = alwaysAvailableSkus;
                this.skuSalesUnavailableReasons = res.unavailable_reasons;
            })
            .catch((err) => {});
    };

    @action handleGetProductionBatches = (setInitialSku = true) => {
        if(!ComponentStockStore.showStockCount()){
            return
        }
        this.loading = true;

        const date = this.date.format('DD-MM-YYYY');
        api.getProductionBatches(date, this.itemType)
            .then((res) => {
                let productionBatches = res.batches
                    .sort((a, b) =>
                        a.sku === b.sku ? 0 : a.sku > b.sku ? 1 : -1
                    )
                    .filter((b) => {
                        if (
                            b.quantity_left !== 0 ||
                            !b.stock_check_tracker ||
                            b.stock_check_tracker.length === 0
                        ) {
                            return true;
                        }
                        let lastStockCheckEntry =
                            b.stock_check_tracker[
                                b.stock_check_tracker.length - 1
                            ];
                        // If last stock check entry quantity and delta are both 0, skip the batch by returning false
                        return !(
                            lastStockCheckEntry.quantity === 0 &&
                            lastStockCheckEntry.delta === 0
                        );
                    });
                this.productionBatches = productionBatches.reduce(
                    (acc, batch) => {
                        const { sku: sku } = batch;
                        this.setBatchStatus(batch);

                        acc[sku] = acc[sku] || {};
                        acc[sku]['batches'] = acc[sku]['batches']
                            ? acc[sku]['batches'].concat([batch])
                            : [batch];

                        return acc;
                    },
                    {}
                );

                if (setInitialSku) {
                    this.handleSetCurrentSku(this.initialSku);
                } else {
                    this.handleSetCurrentSku(this.currentSku);
                }
                this.loading = false;
                this.last_updated = moment();
            })
            .catch((err) => {
                UserStore.message = err.message;
            });
    };

    @action handleGetLiveInventoryBatches = () => {
        this.loading = true;

        const date = this.date.format('DD-MM-YYYY');
        api.getLiveInventoryBatches(date, this.itemTypes)
            .then((res) => {
                let productionBatches = res.batches
                    .sort((a, b) =>
                        a.sku === b.sku ? 0 : a.sku > b.sku ? 1 : -1
                    )
                this.productionBatches = productionBatches.reduce(
                    (acc, batch) => {
                        const { sku: sku } = batch;

                        acc[sku] = acc[sku] || {};
                        acc[sku]['batches'] = acc[sku]['batches']
                            ? acc[sku]['batches'].concat([batch])
                            : [batch];

                        return acc;
                    },
                    {}
                );
                this.loading = false;
            })
            .catch((err) => {
                UserStore.message = err.message;
            });
    };

    setBatchStatus(batch) {
        batch.isFinalCheckCompleted = this.isCheckCompleted(
            batch.stock_check_tracker
        );
    }

    @action handleDeductMarketingActivity = (
        batch
    ) => {
        this.loading = true;
        let request = {
            'menu_item_sku': batch.menuItemSku,
            'reason': batch.reason,
            'delta': batch.delta
        };

        if (request.delta > 0) {
            UserStore.message = "INVALID OPERATION: CANNOT ADD STOCK WITH REASON " + request.reason;
            this.loading = false;
            return;
        }

        api.deductMarketingActivity(request)
            .then(() => {
            }).catch((err) => {
                UserStore.message = err.message;
                this.loading = false;
            }).finally(() =>
                window.location.reload()
            );
    };

    @action handleSetActualStock = (
        batch,
        setInitialSku = true,
        isQuickUpdate = false
    ) => {
        this.loading = true;
        if (isQuickUpdate) {
            let request = {
                'menu_item_sku' : batch.menuItemSku,
                'reason' : batch.reason,
                'delta' : batch.delta,
                'destination_hub_code': batch.destinationHubCode
            };

            if (request.delta > 0 && (request.reason === "LSM_SAMPLING" || request.reason === "EXPIRED" || request.reason === "DAMAGED" || request.reason === "INTER_OUTLET_TRANSFER")) {
                UserStore.message = "INVALID OPERATION: CANNOT ADD STOCK WITH REASON " + request.reason;
                this.loading = false;
                return;
            }

           api.updateUnitBatches(request)
                .then(() => {
                }).catch((err) => {
                    UserStore.message = err.message;
                    this.loading = false;
                }).finally( () =>
                    window.location.reload()
                );
        } else {
            const apiCall = isQuickUpdate
                ? api.setActualQuickStock
                : api.setActualStock;

            if (!isQuickUpdate){
                let now = new Date();
                // if (now.getHours() < InventoryConstants.STOCK_CHECK_START_HOUR && now.getHours() >= InventoryConstants.STOCK_CHECK_END_HOUR){
                //     alert("Stock Check can only be performed between 9 PM and 2 AM")
                //     this.loading = false;
                //     return
                // }
            }

            return apiCall(batch)
                .then((updated_batch) => {
                    if (
                        moment
                            .duration(moment().diff(this.last_updated))
                            .seconds() > 60
                    ) {
                        // do full update to make sure we're up-to-date
                        this.handleGetProductionBatches(setInitialSku);
                    } else {
                        this.setBatchStatus(updated_batch);
                        // only update the changes batch
                        const index = _.findIndex(
                            this.productionBatches[this.currentSku]['batches'],
                            { id: updated_batch.id }
                        );
                        this.productionBatches[this.currentSku]['batches'].splice(
                            index,
                            1,
                            updated_batch
                        );

                        if (setInitialSku) {
                            this.handleSetCurrentSku(this.initialSku);
                        } else {
                            this.handleSetCurrentSku(this.currentSku);
                        }
                    }
                    this.loading = false;
                })
                .catch((err) => {
                    UserStore.message = err.message;
                    this.loading = false;
                });
        }

    };

    @action getVirtualStockForAllSkusAndBatches = () => {
        this.skuList.forEach((sku) => {
            this.handleGetVirtualStockForSkuBatches(sku);
        });
    };

    @action handleGetVirtualStockForSkuBatches = (sku = this.currentSku) => {
        const batches = this.productionBatches[sku]['batches'];
        if (batches){
            batches.forEach((batch) => {
                this.handleGetVirtualStock(batch);
            });
        }
    };

    @action handleGetVirtualStock = (batch) => {
        api.getVirtualStock(batch.menu_item_id, batch.id)
            .then((res) => {
                if(res.virtual_stock > 0){
                batch.virtual_stock = res.virtual_stock;
                } else {
                    batch.virtual_stock = 0;
                }
            })
            .catch((err) => {});
    };
}

const store = new InventoryStore();
export default store;
