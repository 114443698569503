import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import LoginComponent from '../library/LoginComponent';

@inject('UserStore')
@observer
class Login extends Component {
    state = {};

    componentDidMount() {
        const { UserStore } = this.props;
        UserStore.handleGetHubs();
    }

    render() {
        const { UserStore } = this.props;

        return (
            <LoginComponent
                loading={UserStore.loading}
                title="Outlet OS for Pop Meals Outlets"
            />
        );
    }
}

export default Login;
