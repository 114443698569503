export const saveToLocalStorage = (item, value) => {
    localStorage.setItem(item, JSON.stringify(value));
};

export const getFromLocalStorage = (item) => {
    const storedValue = localStorage.getItem(item);
    try {
        return storedValue ? JSON.parse(storedValue) : null;
    } catch (error) {
        return storedValue;
    }
};
