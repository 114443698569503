import { action, observable } from 'mobx';
import * as api from '../services/api';

class OperationDashboardStore {
    @observable closedPopmealsStores = {};
    @observable closedVirtualBrands = {};
    @observable areas = [];
    @observable businessManagers = [];
    @observable outlets = [];
    @observable territoryManagers = [];
    @observable areaOutletMap = {};
    @observable businessManagerOutletMap = {};
    @observable territoryManagerOutletMap = {};
    @observable noOutletManagerClockedIn = [];
    @observable understaffedOutlets = [];
    @observable allIpadsOffline = [];
    @observable outletWithLessMenuItem = [];
    @observable outletWithTopFiveOOS = [];
    @observable receivingNotDone = [];
    @observable cardTerminalNotConnected = [];
    @observable noDitaSalesInTwoHours = [];
    @observable noDeliverySalesInTwoHours = [];
    @observable criticalComponentsOutOfStock = {};
    @observable loading = true;
    @observable dashboardInfoLoading = true;

    @action handleGetAreaByOutletData = () => {
        return api.getAreaByOutletData()
            .then((res) => {
                if (res.areas) {
                    this.areas = [
                        { value: "All", label: "All" },
                        ...res.areas.map((area) => ({
                            value: area,
                            label: area,
                        })),
                    ];
                }
                if (res.business_managers) {
                    this.businessManagers = [
                        { value: "All", label: "All" },
                        ...res.business_managers.map((businessManager) => ({
                            value: businessManager,
                            label: businessManager,
                        })),
                    ];
                }
                if (res.outlets) {
                    this.outlets = [
                        { value: "All", label: "All" },
                        ...res.outlets.map((outlet) => ({
                            value: outlet,
                            label: outlet,
                        })),
                    ];
                }
                if (res.territory_managers) {
                    this.territoryManagers = [
                        { value: "All", label: "All" },
                        ...res.territory_managers.map((territoryManager) => ({
                            value: territoryManager,
                            label: territoryManager,
                        })),
                    ];
                }
                this.areaOutletMap = res.area_outlet_map;
                this.businessManagerOutletMap = res.business_manager_outlet_map;
                this.territoryManagerOutletMap = res.territory_manager_outlet_map;
            })
            .catch((error) => {
                console.error("Error fetching area by outlet data:", error);
            }).finally(() => this.loading = false)

    };
    

    @action handleGetOperationDashboardInfo = (filteredOutlets) => {
        this.dashboardInfoLoading = true;
        if (!filteredOutlets || filteredOutlets.length == 0) {
            this.dashboardInfoLoading = false;
            return;
        }
        api.getOperationDashboardInfo(filteredOutlets)
            .then((res) => {
                this.closedPopmealsStores = res.closed_popmeals_stores;
                this.closedVirtualBrands = res.closed_virtual_brands;
                this.noOutletManagerClockedIn = res.no_outlet_manager_clocked_in;
                this.outletWithLessMenuItem = res.outlet_with_less_menu_item;
                this.outletWithTopFiveOOS = res.outlet_with_top_five_oos;
                this.allIpadsOffline = res.all_ipads_offline;
                this.receivingNotDone = res.receiving_not_done;
                this.cardTerminalNotConnected = res.card_terminal_not_connected;
                this.noDitaSalesInTwoHours = res.no_dita_sales_in_two_hours;
                this.noDeliverySalesInTwoHours = res.no_delivery_sales_in_two_hours;
                this.criticalComponentsOutOfStock = res.critical_components_out_of_stock;
                this.understaffedOutlets = res.under_staffed_outlets;
            })
            .catch((error) => {
                console.error("Error fetching operation dashboard info:", error);
            }).finally(() => this.dashboardInfoLoading = false)
        }
}

const store = new OperationDashboardStore();
export default store;