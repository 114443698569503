import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import InventoryComponentBatchHistoryModal from './InventoryComponentBatchHistoryModal';
import ComponentStockStore from "../../stores/ComponentStockStore";
import InventoryStore from "../../stores/InventoryStore";

const base = 'sku-entry';

@inject('ComponentStockStore')
@observer
class SubComponentStockInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            quantityPerPackaging: [],
        };
    }

    componentDidMount = () => {
        const {
            batch: {unit_sku},
            quantityPerPackaging
        }= this.props;
        let packaging = '';
        if (quantityPerPackaging) {
            packaging = quantityPerPackaging;
        } else {
            packaging = ComponentStockStore.getPackaging({sku: unit_sku});
        }
        this.setState({quantityPerPackaging: packaging});
    }

    handleChange = (e) => {
        const { onChange } = this.props;
        const { name, value, dataset } = e.target;
        let {
            quantityPerPackaging
        }= this.state;
        quantityPerPackaging.filter(p => p.id === name)[0].quantity = parseInt(value);
        this.setState({quantityPerPackaging});
        onChange(dataset.sku, this.calculateUnitQuantity(), quantityPerPackaging);
    };

    calculateUnitQuantity = () => {
        const {
            quantityPerPackaging
        }= this.state;
        let totalQuantity = 0;
        for (const packaging of quantityPerPackaging) {
            if (!packaging.quantity) {
                continue;
            }
            totalQuantity += packaging.quantity * packaging.innerQuantity;
        }
        return totalQuantity;
    }

    render() {
        const {
            batch: {
                unit_sku,
                name
            },
        } = this.props;
        const {
            quantityPerPackaging
        } = this.state;

        return (
            <Fragment>
                <div className={`${base} display--flex justify--center`} style={{paddingBottom: '0px'}}>                    
                    <div className="col-lg-6" style={{width: '500px'}}>
                        <div className="display--flex width--100 gap--10 pts">
                            <label className='fs--medium-medium-large' style={{width: '100px'}}>+</label>
                        </div>
                        {quantityPerPackaging && quantityPerPackaging.map((p, index) => (
                            <Fragment key={p.id}>
                                {index > 0 && <div className="plus-separator text--center"><label className='fs--medium-medium-large'>+</label></div>}
                                <div className="input-row display--flex justify--center gap--10">
                                    <input
                                        className='quantity-input border--rounded border--solid fs--medium-medium-large text--center text--black text--bolder ps'
                                        type='number'
                                        name={p.id}
                                        data-sku={unit_sku}
                                        min={0}
                                        value={p.quantity === undefined ? '' : p.quantity}
                                        onChange={this.handleChange}
                                    />
                                    <p className='packaging-label fs--medium-medium-large text--left text--black text--sofiapro'>
                                        {p.packaging}
                                        {/* <span className='text--thin'>{' (' + unit_sku + ' : ' + name + ')'}</span> */}
                                    </p>
                                </div>
                            </Fragment>
                        ))}
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default SubComponentStockInput;

<style jsx>{`
    .input-row {
        display: flex;
        align-items: center;
        width: 100%;
        margin: 0.5rem 0;
    }

    .quantity-input {
        width: 100px;
        min-width: 100px;
    }

    .packaging-label {
        flex: 1;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .plus-separator {
        padding: 0.5rem 0;
        width: 100px;
    }
`}</style>
