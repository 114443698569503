import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import PrintStickersModal from '../instaService/PrintStickerModal';
import Modal from '../Modal';

const base = 'cooking-v2';

@inject('ProductionStore', 'DispatchStore')
@observer
class InstaServiceProductionCategory extends Component{
    state = {
        hours: '--',
        minutes: '--',
        seconds: '--',
        appearTimestamp: null,
        showConfirmationModal: false,
    };

    componentDidMount = () => {
        const { category, ProductionStore } = this.props;
        const then = moment();

        if (ProductionStore.lastOrderTimeStamp == null || category.created_date > ProductionStore.lastOrderTimeStamp) {
            ProductionStore.lastOrderTimeStamp = category.created_date;
        }

        if (!this.state.appearTimestamp) {
            this.setState({ appearTimeStamp: then });
        }
        this.setCountdown();
    };

    componentDidUpdate = (prevProps) => {
        const { category } = this.props;
        if (category.created_date !== prevProps.category.created_date) {
            this.resetCountdown();
            this.setCountdown();
        }
    };

    componentWillUnmount() {
        this.resetCountdown();
    }

    resetCountdown = () => {
        if (this.interval) clearInterval(this.interval);
        this.setState({
            hours: '--',
            minutes: '--',
            seconds: '--',
        });
    };

    setCountdown = () => {
        this.interval = setInterval(this.countdown, 1000);
    };

    countdown = () => {
        const { category, ProductionStore } = this.props;
        const now = moment(category.created_date, 'x');
        const then = moment();

        const duration = moment.duration(then.diff(now)).asMilliseconds();

        const hours = moment.utc(duration).format('H');
        const minutes = moment.utc(duration).format('mm');
        const seconds = moment.utc(duration).format('ss');

        if (duration < 0) {
            // future order.
            this.setState({
                hours,
                minutes,
                seconds,
            });
        } else {
            if (this.state.appearTimestamp == null) {
                this.setState({ hours, minutes, seconds, isNewOrder: true, appearTimestamp: then });
            } else {
                this.setState({
                    hours,
                    minutes,
                    seconds
                });
            }
        }
    };

    handlePrintIconClick = () => {
        this.setState({ showConfirmationModal: true });
    };

    handleModalState = (state) => {
        this.setState({ showConfirmationModal: state });
    };

    chunkArray = (array, chunkSize) => {
        let results = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            results.push(array.slice(i, i + chunkSize));
        }
        return results;
    }

    handleMarkCategoryDone = (category) => {
        const { DispatchStore } = this.props;
        const skuString = category.items.map(item => item.sku).join(',');
        const quantityString = category.items.map(item => item.quantity).join(',');

        DispatchStore.handledScannedWarmerItem(category.id, skuString, quantityString);
    }

    render() {
        const { category } = this.props;
        const {
            hours,
            minutes,
            seconds,
            showConfirmationModal,
        } = this.state;

        const chunkedItems = this.chunkArray(category.items, 3);

        return (
        <div className={`${base}__warmer_sku_category`}>
            {category.items != null &&
            category.items.length > 0 && (
                <table className={`${base}__warmer_sku_items_table`}>
                    <thead>
                        <th colSpan={chunkedItems.length}>
                            {/* Create header columns based on the number of chunks */}
                            <div className={`${base}__warmer_category_name display--flex`} style={{justifyContent: 'space-between'}}>
                                <div>{category.category}</div>
                                <div className='display--flex' style={{alignItems: 'center'}}>
                                    <p style={{width: '6rem', overflow: 'hidden'}}>
                                            {`${
                                                parseInt(hours) > 0 ? `${hours}:` : ``
                                            }${minutes}:${seconds}`}
                                    </p>
                                    <button
                                        className={`${base}__print btn bg--white`}
                                        onClick={(e) => {
                                            this.handlePrintIconClick({
                                                category
                                            });
                                        }}
                                        >
                                        <svg className="icon-print-medium" style={{height: '100%', width: '100%'}}>
                                            <use xlinkHref={`#icon-print-medium`} />
                                        </svg>
                                    </button>
                                    <button
                                        className={`${base}__print btn bg--white`}
                                        onClick={(e) => {
                                            this.handleMarkCategoryDone(category);
                                        }}
                                        style={{paddingTop: '7px', marginLeft: '4px'}}
                                        >
                                        <svg className="icon-print-medium" style={{height: '100%', width: '100%'}}>
                                            <use xlinkHref={`#icon-check-black`} />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </th>
                    </thead>
                    <tbody style={{backgroundColor: '#FFFCF0'}}>
                        {/* Loop through the chunked array and create rows */}
                        {chunkedItems[0].map((_, rowIndex) => (
                            <tr key={`row-${rowIndex}`} style={{ borderSpacing: 0}}>
                                {chunkedItems.map((col, colIndex) => (
                                    <td key={`cell-${rowIndex}-${colIndex}`} >
                                        {/* Ensure item exists in the column */}
                                        {chunkedItems[colIndex][rowIndex] && (
                                            <div className={`${base}__warmer_sku_items`}>
                                                <div className={`${base}__warmer_item_container`}>
                                                    <span className={`${base}__warmer_item_name`}>
                                                        {chunkedItems[colIndex][rowIndex].quantity} x {chunkedItems[colIndex][rowIndex].name || ''}
                                                    </span>
                                                    {chunkedItems[colIndex][rowIndex].oos && (
                                                            <p className={`${base}__oos-tag`}>OOS</p>
                                                        )}
                                                </div>
                                            </div>
                                        )}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
            {showConfirmationModal && (
                    <Modal
                        customStyles={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        width={'45%'}
                        height={'56%'}
                        padding="0px"
                        borderRadius="0px"
                    >
                        <PrintStickersModal
                            handleModalState={this.handleModalState}
                            sendToastNotification={this.sendToastNotification}
                            handlePrintBtnState={this.handlePrintBtnState}
                            category={category.category}
                        />
                    </Modal>
                )}
        </div>
        )
    };
}
export default InstaServiceProductionCategory;
