import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Fullscreen from 'react-full-screen';
import {Helmet} from "react-helmet";
import InstaServiceProduction from './InstaServiceProduction';
import CurrentWarmerStock from './CurrentWarmerStock';
import Webcam from 'react-webcam';
import BarcodeReader from 'react-barcode-reader';
import ScanningBox from '../ScanningBox';

const base = 'insta-service';
const ONE_SECOND = 1000;

@inject('OrdersKitchenStore', 'WarmerStore', 'UserStore', 'LiveDispatchStore', 'ScanningStore')
@observer
class InstaService extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFullscreen: false,
        };
        this.webcamRef = React.createRef();
    }

    componentDidMount() {
        const {
            OrdersKitchenStore: {
                handleGetInstaServiceProductions,
            },
            UserStore,
        } = this.props;
        const {
            selectedHub: { isOnDemandProduction },
            handleSetFullscreen
        } = UserStore;
        handleSetFullscreen(false)

        if (isOnDemandProduction) {
            handleGetInstaServiceProductions(true);
            this.interval = setInterval(() => {
                handleGetInstaServiceProductions(false);
            }, 10 * ONE_SECOND); // 10 seconds
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    handleError = (err) => {};

    handleScanWrapper = (scannedBarcode) => {
        const { LiveDispatchStore, ScanningStore } = this.props;
        const { setItemImage, handledScannedItem, handledScannedBag, handledScannedWarmerItem, displayErrorResultFunc } =
            LiveDispatchStore;
        const { handleScan } = ScanningStore;
        if (this.webcamRef.current) {
            const image = this.webcamRef.current.getScreenshot();
            setItemImage(image);
        }
        let isBagQRCode = scannedBarcode.split('-').length == 1;
        if (isBagQRCode) {
            handledScannedBag(scannedBarcode);
        } else {
            handleScan(scannedBarcode, handledScannedItem, handledScannedWarmerItem, displayErrorResultFunc);
        }
    };

    render() {
        const { LiveDispatchStore, OrdersKitchenStore, UserStore } = this.props;
        const {
            isCurrentPageFullScreen: isFullscreen,
            handleSetFullscreen,
            handleExitFullscreen,
            selectedHub: { isOnDemandProduction },
        } = UserStore;
        const {
            instaServiceProductions,
            currentWarmerStocks
        } = OrdersKitchenStore;
        const {
            itemImage: image,
            itemScanLoading,
            scannedItem,
            scannedItemSKU,
            scannedItemError,
            showScannedItemOnLiveDispatch,
            originalItemImageLink,
        } = LiveDispatchStore;

        return (
            <Fullscreen
                enabled={isFullscreen}
                onChange={(isFullscreen) => {
                    if (isFullscreen) {
                        handleSetFullscreen(isFullscreen);
                    } else {
                        handleExitFullscreen();
                    }
                }}
            >
                <Helmet>
                    <title>InstaService | Outlet OS | Pop Meals</title>
                </Helmet>
                {isOnDemandProduction && (
                    <div className="width--100">
                        <div className={`${base}__webcam-area`}>
                            <Webcam
                                audio={false}
                                ref={this.webcamRef}
                                screenshotFormat="image/jpeg"
                                className="webcam"
                                screenshotQuality={1}
                                forceScreenshotSourceSize={true}
                                videoConstraints={{
                                    height: 1080,
                                    width: 1920,
                                }}
                            />
                        </div>
                        <div
                            className={`${
                                isFullscreen && 'full-screen'
                            } display--flex`}
                        >
                            {instaServiceProductions && 
                            instaServiceProductions.length > 0 && (
                                <div 
                                    className={`${
                                        isFullscreen ? 'insta-service__insta_service_production_container_full-screen' : 'insta-service__insta_service_production_container'
                                }`}>
                                    <InstaServiceProduction
                                        instaServiceProductions={instaServiceProductions}
                                    />
                                </div>
                            )}
                            
                            <div 
                            className={`${
                                isFullscreen ? 'insta-service__warmer_stock_container_full-screen' : 'insta-service__warmer_stock_container'
                            }`}>
                                <CurrentWarmerStock
                                    currentWarmerStocks={currentWarmerStocks}
                                />
                            </div>
                        </div>
                    </div>
                )}
                <BarcodeReader
                    onError={this.handleError}
                    onScan={this.handleScanWrapper}
                />
                <ScanningBox
                    show={showScannedItemOnLiveDispatch}
                    scannedItem={scannedItem}
                    scannedItemSKU={scannedItemSKU}
                    scannedItemError={scannedItemError}
                    handleScan={this.handleScanWrapper}
                    loading={itemScanLoading}
                    originalItemImageLink={originalItemImageLink}
                    itemImage={image}
                    isCurrentPageFullScreen={isFullscreen}
                />
            </Fullscreen>
        );
    }
}

export default InstaService;
