import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import Loading from '../Loading';
import InventoryHeader from './InventoryHeader';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import SKURowAdjustmentUpdate from './SKURowAdjustmentUpdate';
import InventoryComponentStockUpdate from './InventoryComponentStockUpdate';

const base = 'inventory-adjustment';

@inject('InventoryStore')
@observer
class InventoryAdjustmentUpdate extends Component {
    initialState = {
        showAdjustmentModal: false,
        quantity: 0,
        selectedBatch: {},
        showConfirmationModal: false,
        selectedReason: {},
    };

    state = this.initialState;

    componentDidMount = () => {
        const {
            match: {
                params: { type },
            },
            InventoryStore: {
                handleGetProductionBatches,
                handleSetItemType,
                handleGetSKUSalesAvailability,
            },
        } = this.props;

        const itemType = type === 'meals' ? 'Item' : type === 'addons' ? 'Addons' : 'Component';
        if (itemType === 'Component') {

        } else {
            handleGetSKUSalesAvailability(itemType);
            handleSetItemType(itemType);
            handleGetProductionBatches(false);
        }
    };

    handleUnselectSku = () => {
        const {
            InventoryStore: { handleSetCurrentSku },
        } = this.props;

        handleSetCurrentSku('');
        this.setState({ quantity: 0 });
    };


    render() {
        const {
            InventoryStore: {
                loading,
                productionBatches,
                skusByCategory,
                currentSku,
                itemType
            }
        } = this.props;

        if (loading) {
            return <Loading />;
        }

        if (!currentSku) {
            return (
                <Fragment>
                    <InventoryHeader title='Choose SKU to update' />
                    <div className='pm' style={{ maxWidth: '80%' }}>
                        <p className='fs--medium-large text--sofiapro text--left mbm'>
                            Select SKU
                        </p>
                        {Object.keys(skusByCategory).map((category) => (
                            <div
                                key={category}
                                className={`${base}__skus_of_one_sku_letter`}>
                                <div className="row col-xs-12" style={{marginTop: '48px', marginBottom: '24px'}}>
                                    <div className="col-xs-4" style={{paddingLeft: '5%'}}>
                                        <h3 className='text--left fs--medium-large text--bolder'>{category}</h3>
                                    </div>
                                    <div className="col-xs-2">
                                        <h2 className='fs--medium-medium-large text--bolder'>
                                            Unsold Stock
                                        </h2>
                                    </div>
                                    <div className="col-xs-2">
                                        <h2 className='fs--medium-medium-large text--bolder'>
                                            To hold
                                        </h2>
                                    </div>
                                    {itemType === 'Item' && (
                                        <div className="col-xs-2">
                                            <h2 className='fs--medium-medium-large text--bolder'>
                                                Marketing Activity
                                            </h2>
                                        </div>
                                    )}
                                </div>
                                {skusByCategory[category].map((sku) => {
                                    return (
                                        <SKURowAdjustmentUpdate
                                            sku={sku}
                                            itemType={itemType}
                                            key={sku}
                                        />
                                    );
                                })}
                            </div>
                        ))}
                    </div>
                </Fragment>
            );
        }

        const { batches } = productionBatches[currentSku];
        const startDate = (batch) =>
            moment(batch.arrival_at, 'x').format('DD/MM/YYYY');
        const endDate = (batch) =>
            moment(batch.expires_at, 'x')
                .subtract(1, 'days')
                .format('DD/MM/YYYY');

        return (
            <Fragment>
                <InventoryHeader
                    title={`${
                        itemType === 'Item'
                            ? currentSku.split('_')[0]
                            : currentSku
                    } Quick Stock Update`}
                    onClickBackLink={this.handleUnselectSku}
                />
                {batches
                    .slice()
                    .sort((a, b) => a.expires_at - b.expires_at)
                    .map((batch) => (
                        <Fragment key={batch.id}>
                            <div
                                className={`fs--medium-large ps bg--black text--white`}
                            >
                                {`${startDate(batch)} - ${endDate(batch)}`}
                            </div>
                            <div
                                className={`pl row col-lg-12`}
                            >
                                <div className='text--left col-lg-6 col-sm-12 col-xs-12'>
                                    <p className='text--bolder fs--medium-large'>
                                        {itemType === 'Item'
                                            ? currentSku.split('_')[0]
                                            : currentSku}
                                    </p>
                                    <p className='fs--medium-large'>{`${batch.virtual_stock} Meals in the Hub`}</p>
                                    <p className='fs--medium-large'>{`${batch.virtual_stock - batch.quantity_left} Pre-orders`}</p>
                                </div>
                            </div>
                            <div className={`pl row col-lg-12`}>
                            <InventoryComponentStockUpdate  fromMealUpdateScreen ={true}/>
                            </div>
                        </Fragment>
                    ))}
            </Fragment>
        );
    }
}

export default withRouter(InventoryAdjustmentUpdate);
