import { action, computed, observable } from 'mobx';
import * as api from '../services/api';
import InventoryConstants from '../components/inventory/InventoryConstants';
import UserStore from "./common/UserStore";
import InventoryStore from "./InventoryStore";
import { getFromLocalStorage } from '../services/utils';
import moment from 'moment';


class ComponentStockStore {
    @observable unitSkus;
    @observable selectedUnitSku;
    @observable selectedStatus = { value: 'STOCKED', label: 'STOCKED' };
    @observable supplier;
    @observable batchId;
    @observable quantityPerPackaging = [];
    @observable unitSkusAndPackaging;
    @observable unitBatches = [];
    @observable unitBatchesHistory = [];
    @observable unitSkuList = {};
    @observable componentStockUpdateLoading = true;
    @observable componentStockPrintLoading = true;
    @observable inventoryOverview;
    @observable inventoryOverviewLoading = true;
    @observable allUnitSkus = [];
    @observable componentDailyStockLoading = true;
    @observable stockCountList = {}

    getPackaging = (unitSku) => {
        let filteredSku = this.unitSkusAndPackaging.filter(u => u.id === unitSku.value || u.sku === unitSku.sku);
        if (filteredSku.length === 0) {
            return [];
        }

        return filteredSku[0].packaging.sort(((a, b) => b.number_of_base_uom - a.number_of_base_uom)).reduce(function(result, p){
            if (getPackagingLabel(p.specs) != null){
                result.push({ id: p.id, packaging: getPackagingLabel(p.specs), innerQuantity:  getInnerQuantity(p.specs)});
            }
            return result;
        }, []);

        function getInnerQuantity(specs){
            let quantity = 1;
            for (const spec of specs) {
                if (!spec.outer_unit_of_measurement) {
                    return;
                }
                if (spec.inner_quantity && spec.inner_unit_of_measurement) {
                    quantity *= spec.inner_quantity;
                }
            }
            return quantity;
        }

        function getPackagingLabel(specs) {
            let name = specs[0].outer_unit_of_measurement;
            let openBracketCount = 0;
            for (const spec of specs) {
                if (!spec.outer_unit_of_measurement) {
                    return;
                }
                if (spec.inner_quantity && spec.inner_unit_of_measurement) {
                    if (spec.inner_quantity == 1) {
                        return;
                    }
                    openBracketCount++;
                    name += '(' + spec.inner_quantity + ' ' + spec.inner_unit_of_measurement;
                }
            }
            for (let i = 0; i < openBracketCount; i++) {
                name = name + ')';
            }
            return name;
        }
    }

    getBasePackaging = (unitSku) => {
        let filteredSku = this.unitSkusAndPackaging.filter(u => u.id === unitSku.value || u.sku === unitSku.sku);
        if (filteredSku.length === 0) {
            return "meals";
        }

        for (const packaging of filteredSku[0].packaging) {
            if (packaging.specs.length == 1) {
                return packaging.specs[0].outer_unit_of_measurement;
            }
        }
        return "meals";
    }

    @action setSelectedUnitSku = (unitSku) => {
        this.selectedUnitSku = unitSku;
        this.quantityPerPackaging = this.getPackaging(unitSku);
    };
    @action
    setSelectedStatus = (status) => {
        this.selectedStatus = status;
    };

    @action
    setSupplier = (e) => {
        const { value } = e.target;
        this.supplier = value;
    };

    @action
    setBatchId = (e) => {
        const { value } = e.target;
        this.batchId = value;
    };

    @action
    handleSaveComponentStock = (quantityPerPackaging) => {
        let request = {
            'unit_sku': this.selectedUnitSku.value,
            'supplier': this.supplier,
            'status': this.selectedStatus.value,
            'batch_id': this.batchId,
            'quantities': quantityPerPackaging
                .map(e => {
                    return { 'quantity': e.quantity, 'packaging_id': e.id };
                }),
        };
        return api.saveComponentStock(request);
    };

    showStockCount = () => {
        let now = new Date();
        let pickupAddress = getFromLocalStorage('hub').pickupAddress;
        let showStockCount = true;
        const isEOW = now.getDay() == 0 || (now.getDay() == 1 && now.getHours() < 2);
        if(pickupAddress != null && pickupAddress.dispatch_start_time != pickupAddress.dispatch_end_time){
            let gapBetweenClosingAndCurrentTime = (pickupAddress.closing_time[0] - now.getHours()) * 60 + (pickupAddress.closing_time[1] - now.getMinutes())
            let gapBetweenOpeningAndCurrentTime = (pickupAddress.opening_time[0] - now.getHours()) * 60 + (pickupAddress.opening_time[1] - now.getMinutes())
            showStockCount = gapBetweenClosingAndCurrentTime > 60 && gapBetweenOpeningAndCurrentTime < 180
        }

        return showStockCount || !isEOW;
    }

    @action
    handleGetUnitSkus = (callback) => {
        api.getUnitSkus()
            .then(res => {
                this.unitSkusAndPackaging = res;
                this.unitSkus = this.unitSkusAndPackaging
                    .filter(u => u.packaging && u.packaging.length > 0)
                    .map(u => {
                        return { value: u.id, label: u.sku + ' : '+ u.name, subComponent: u.sub_component };
                    });
                if (callback) {
                    callback();
                }
            })
            .catch((error) => console.log(error));
    };

    @action
    getAllUnitSkus = () => {
        if(!this.showStockCount()){
            this.componentStockUpdateLoading = false;
            return
        }
        api.getUnitSkus()
            .then(res => {
                this.allUnitSkus = res;
            })
            .catch((error) => console.log(error))
            .finally(() => this.componentStockUpdateLoading = false);
    };

    @action
    cleanUpForm = () => {
        this.selectedUnitSku = null;
        this.quantityPerPackaging = [];
        this.supplier = '';
        this.batchId = '';
        this.selectedStatus = { value: 'STOCKED', label: 'STOCKED' };
    };

    @action
    handleGetUnitBatches = () => {
        if(!this.showStockCount()){
            this.componentStockUpdateLoading = false;
            return
        }
        api.getUnitBatches()
            .then(res => {
                this.buildSkuListForQuickSkuUpdate(res)
                api.getUnitBatchesHistory()
                    .then(history => {
                        this.unitBatchesHistory = history;
                    })
            })
            .catch(error => console.log(error))
            .finally(() => this.componentStockUpdateLoading = false);
    };

    buildSkuListForQuickSkuUpdate = (result) => {
        if(this.allUnitSkus){
            const subComponents = this.allUnitSkus.filter(u => u.parent_component_sku != null)
            if(subComponents && subComponents.length > 0){
                subComponents.forEach((component) => {
                    if (result.some(e => e.unit_sku === component.sku)) {
                        return;
                    }
                    let input = {
                        unit_sku: component.sku,
                        quantity: 0,
                        pre_order_quantity: 0,
                        virtual_stock: 0,
                        name: component.name,
                    }
                    result.push(input)
                })
            }
        }

        this.unitBatches = result;
    }

    isStockCheckCompleted = (unitSku) => {
        const timestampFieldName = 'final_check_timestamp'
        // return (
        //     unitSku[timestampFieldName] && InventoryStore.isUpdatedInTimeFrame(unitSku[timestampFieldName])
        // );
        let startOfDate = moment('00:00', 'HH:mm');
        const today = new Date();
        if (today.getHours() < 3) {
            startOfDate = startOfDate.subtract(1, 'days');
        }
        return unitSku[timestampFieldName] && unitSku[timestampFieldName] > startOfDate;
    };

    isEligibleForDailyStockCheck = (sku) => {
        if (!sku) {
            return false;
        } else {
            return true;
        }
        
        const today = new Date();
        if (today.getDay() == 0 || (today.getDay() == 1 && today.getHours() < 3)) {
            return true;
        }
        return false;
    }

    buildSkuListForDailyStockCheck = (unitBatches, unitBatchesHistory) => {
        this.unitSkusList = {};
        if (!this.unitSkusAndPackaging) {
            return;
        }
        for (const batch of unitBatches) {
            if(!batch.unit_sku || !this.isEligibleForDailyStockCheck(batch.sub_component ? batch.parent_component_sku : batch.unit_sku)) {
                continue;
            }

            if (!this.unitSkusList[batch.unit_sku]) {
                this.unitSkusList[batch.unit_sku] = {
                    unit_sku: batch.unit_sku,
                    unsold: batch.quantity,
                    pre_order_quantity: batch.pre_order_quantity,
                    virtual_stock: batch.quantity + batch.pre_order_quantity,
                    initial_check_timestamp: batch.initial_check_timestamp,
                    final_check_timestamp: batch.final_check_timestamp,
                    name: batch.unit_name,
                    is_sub_component: batch.sub_component,
                    parent_component_sku: batch.parent_component_sku,
                    latest_arrival_at: batch.arrival_at,
                    category: batch.category
                };
            } else {
                const isNew = batch.arrival_at > this.unitSkusList[batch.unit_sku].latest_arrival_at;
                this.unitSkusList[batch.unit_sku] = {
                    unit_sku: batch.unit_sku,
                    unsold: this.unitSkusList[batch.unit_sku].unsold + batch.quantity,
                    pre_order_quantity: this.unitSkusList[batch.unit_sku].pre_order_quantity + batch.pre_order_quantity,
                    virtual_stock: this.unitSkusList[batch.unit_sku].virtual_stock + batch.quantity + batch.pre_order_quantity,
                    initial_check_timestamp: batch.initial_check_timestamp,
                    final_check_timestamp: batch.final_check_timestamp,
                    name: isNew ? batch.unit_name : this.unitSkusList[batch.unit_sku].name,
                    is_sub_component: batch.sub_component,
                    parent_component_sku: batch.parent_component_sku,
                    latest_arrival_at: isNew ? batch.arrival_at : this.unitSkusList[batch.unit_sku].latest_arrival_at,
                    category: isNew ? batch.category : this.unitSkusList[batch.unit_sku].category
                };
            }
        }

        if(this.unitSkusAndPackaging){
            const subComponents = this.unitSkusAndPackaging
            .filter(u => u.packaging && u.packaging.length > 0 && u.parent_component_sku != null && this.isEligibleForDailyStockCheck(u.parent_component_sku))
            if(subComponents && subComponents.length > 0){
                subComponents.forEach((component) => {
                    if(!this.unitSkusList[component.sku]){
                        this.unitSkusList[component.sku] = {
                            unit_sku: component.sku,
                            unsold: 0,
                            pre_order_quantity: 0,
                            virtual_stock: 0,
                            initial_check_timestamp: null,
                            final_check_timestamp: null,
                            name: component.name,
                            is_sub_component: true,
                            parent_component_sku: component.parent_component_sku,
                            expire_sub_component_at_night: component.expire_sub_component_at_night,
                            breakdown_quantity : component.breakdown_quantity,
                            sub_component_type : component.sub_component_type
                        };
                    } else {
                        this.unitSkusList[component.sku] = {
                            unit_sku: component.sku,
                            unsold: this.unitSkusList[component.sku].unsold,
                            pre_order_quantity: this.unitSkusList[component.sku].pre_order_quantity,
                            virtual_stock: this.unitSkusList[component.sku].virtual_stock,
                            initial_check_timestamp: this.unitSkusList[component.sku].initial_check_timestamp,
                            final_check_timestamp: this.unitSkusList[component.sku].final_check_timestamp,
                            name: component.name,
                            is_sub_component: true,
                            parent_component_sku: component.parent_component_sku,
                            expire_sub_component_at_night: component.expire_sub_component_at_night,
                            breakdown_quantity : component.breakdown_quantity,
                            sub_component_type : component.sub_component_type
                        }; 
                    }
                })
            }
        }

        Object.keys(this.unitSkusList).forEach(sku => {
            if (!this.unitSkusList[sku].is_sub_component || !this.unitSkusList[this.unitSkusList[sku].parent_component_sku]){
                return
            }
            if(!this.unitSkusList[this.unitSkusList[sku].parent_component_sku].sub_component){
                this.unitSkusList[this.unitSkusList[sku].parent_component_sku].sub_component = []
            }
            this.unitSkusList[this.unitSkusList[sku].parent_component_sku].sub_component.push(this.unitSkusList[sku])
        })

        Object.keys(this.unitSkusList).forEach(sku => {
            const stock_check_tracker = unitBatchesHistory.filter((entry) => {
                return entry.unit_sku == sku && entry.reason === 'DAILY_STOCK_CHECK';
            });
            this.unitSkusList[sku].stock_check_tracker = stock_check_tracker;
            this.unitSkusList[sku].isFinalCheckCompleted = this.isStockCheckCompleted(this.unitSkusList[sku]);
        })

        // Convert object to array of key-value pairs
        const skuArray = Object.entries(this.unitSkusList);
        skuArray.sort((a, b) => this.sortByCategory(a[1], b[1]));
    
        // Reconstruct the object from the sorted array
        this.unitSkusList = {};
        for (const [key, value] of skuArray) {
            this.unitSkusList[key] = value;
        }
        this.unitSkuList = this.unitSkusList;
    }

    sortByCategory = (a, b) => {
        const categoryA = a.category ? a.category : "";
        const categoryB = b.category ? b.category : "";        
        if (categoryA < categoryB) {
            return -1;
        } else if (categoryA > categoryB) {
            return 1;
        }
        return 0;
    };

    @action
    handleGetUnitBatchesForDailyStockCheck = () => {
        api.getUnitBatches()
            .then(batches => {
                api.getUnitBatchesHistory()
                    .then(history => {
                        this.buildSkuListForDailyStockCheck(batches, history);
                    })
                    .catch(error => console.log(error))
                    .finally(() => this.componentStockUpdateLoading = false);
            })
            .catch(error => console.log(error))
            .finally(() => this.componentStockUpdateLoading = false);
    };

    @action
    handleUpdateUnitBatch = (updateRequest) => {
        let hubCode = getFromLocalStorage('hub').value;
        if (hubCode === updateRequest.destinationhubCode) {
            UserStore.message = "INVALID OPERATION: Destination and current Hub can't be the same ";
            return;
        }
        
        let request = {
            'unit_sku': updateRequest.unitSku,
            'quantity': updateRequest.quantity,
            'reason' : updateRequest.reason,
            'delta': updateRequest.delta,
            'destination_hub_code': updateRequest.destinationhubCode
        };

        if (request.delta > 0 && (request.reason === "LSM_SAMPLING" || request.reason === "EXPIRED" || request.reason === "DAMAGED" || request.reason === "INTER_OUTLET_TRANSFER")) {
            UserStore.message = "INVALID OPERATION: CANNOT ADD STOCK WITH REASON " + request.reason;
            return;
        }

        this.componentStockUpdateLoading = true;
        return api.updateUnitBatch(request)
            .then(res => {
                this.handleGetUnitBatches();
            })
            .catch((err) => {
                UserStore.message = err.message;
            })
            .finally(() => this.componentStockUpdateLoading = false);
    };

    @action
    handleGetLiveInventoryOverview = () => {
        if(!this.showStockCount()){
            this.inventoryOverviewLoading = false;
            return
        }
        api.getLiveInventoryOverview()
            .then(res => {
                this.inventoryOverview = res;
            })
            .catch(error => console.log(error))
            .finally(() => this.inventoryOverviewLoading = false);
    };

    @computed get skusByCategoryForLiveInventoryComponent() {
        const unsortedCategoryObject = this.skusInCategoryForLiveInventoryComponent
        //Show Snacks -> Desserts -> Drinks for addons
        if (unsortedCategoryObject && Object.keys(unsortedCategoryObject).length > 0) {
            return {"Meals": unsortedCategoryObject["Meals"], "Snacks": unsortedCategoryObject['Snacks'], "Desserts": unsortedCategoryObject['Desserts'], "Drinks": unsortedCategoryObject['Drinks']}
        } else {
            return {}
        }
    }

    sortByMandatory = (a, b) => {
        if (a.mandatory && !b.mandatory) {
            return -1;
        } else if (!a.mandatory && b.mandatory) {
            return 1;
        }
        return 0;
    }

    @computed get skusInCategoryForLiveInventoryComponent() {
        if (this.inventoryOverview) {
            for (const item of this.inventoryOverview.item_inventory_list) {
                item.component_inventories.replace(item.component_inventories.slice().sort(this.sortByMandatory));
            }

            const sortedObject = this.inventoryOverview.item_inventory_list.sort((a, b) => (a.menu_item_ranking > b.menu_item_ranking ? 1 : -1));
            return sortedObject.reduce((acc, res) => {
                    const category = res['menu_item_category'];
                    acc[category] = acc[category] ? acc[category].concat([res]) : [res];
                return acc;
            }, {});
        }
    }

    @action
    handleBulkUpdateUnitBatch = (updateRequests) => {
        this.componentStockUpdateLoading = true;
        let count = 0;
        for (const updateRequest of updateRequests) {
            let request = {
                'unit_sku': updateRequest.unitSku,
                'quantity': updateRequest.quantity,
                'delta': updateRequest.delta,
                'sub_component_unit_update_request': updateRequest.subComponentUnitUpdateRequest,
                'reason': 'DAILY_STOCK_CHECK',
                'submitter': updateRequest.submitter
            };
            api.updateUnitBatch(request)
                .then(res => {
                    count++;
                    if (count == updateRequests.length) {
                        this.handleGetUnitBatchesForDailyStockCheck();
                    }
                }).catch((err) => {
                UserStore.message = err.message;
                this.componentStockUpdateLoading = false;
            })
        }
    };

    @action
    handleGetStockCountList = () => {
        api.getStockCountList()
        .then(res => {
            this.stockCountList = res;
            this.componentStockPrintLoading = false
        })
        .catch((error) => console.log(error))
        .finally(() => this.componentStockPrintLoading = false);
    }
}

const store = new ComponentStockStore();
export default store;
