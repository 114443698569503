import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import Modal from '../Modal';

@observer
class InventoryComponentBatchHistoryModal extends Component {
    state = { showBatchHistoryModal: false };

    handleShowBatchHistoryModal = () => {
        this.setState({ showBatchHistoryModal: true });
    };

    handleCloseBatchHistoryModal = () => {
        this.setState({ showBatchHistoryModal: false});
    };

    render() {
        const {
            history
        } = this.props;
        const { showBatchHistoryModal } = this.state;

        return (
            <div>
                <button
                    className="btn btn-primary"
                    onClick={() =>
                        this.handleShowBatchHistoryModal()
                    }
                >
                    Stock Edit History
                </button>
                {showBatchHistoryModal && (
                    <Modal>
                        <div className="mbm text--center">
                            <div className="history-modal">
                                <span>Update history last 2 days</span>
                                <table className="history-table">
                                    <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>New Quantity</th>
                                        <th>Reason</th>
                                        <th>User</th>
                                        <th>Submitter</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {history &&
                                    history.map((stock)=>
                                        <tr key ={stock.updated_at}>
                                            <td>{moment(stock.updated_at, 'x').format('DD/MM/YYYY HH:mm A')}</td>
                                            <td>{stock.quantity}</td>
                                            <td>{stock.reason}</td>
                                            <td>{stock.initiator_username}</td>
                                            <td>{stock.submitter}</td>
                                        </tr>)}
                                    </tbody>
                                </table>
                            </div>

                            <button
                                className="btn flex-1"
                                onClick={this.handleCloseBatchHistoryModal}
                            >
                                Close
                            </button>
                        </div>
                    </Modal>
                )}
            </div>
        );
    }
}

export default InventoryComponentBatchHistoryModal;
