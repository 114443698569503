import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Helmet } from "react-helmet";
import Select from 'react-select';
import Loading from '../Loading';
import Webcam from 'react-webcam';
import BarcodeReader from 'react-barcode-reader';
import ScanningBox from '../ScanningBox';
import qs from 'qs';
import { reaction } from 'mobx';
import Cookies from 'js-cookie';

const base = 'operation-dashboard';

@inject('OperationDashboardStore', 'LiveDispatchStore')
@observer
class OperationDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedAreas: [],
            selectedBusinessManagers: [],
            selectedTerritoryManagers: [],
            selectedOutlets: [],
            filteredOutlets: [],
            rowOffsets: [],
            currentRowIndex: 0,
        };
        this.interval = null;
        this.webcamRef = React.createRef();
        this.refreshInterval = null;
        this.autoScrollInterval = null;
    }

    componentDidMount() {
        const parameters = qs.parse(this.props.location.search.slice(1));
        const { token, area, businessManager, territoryManager, outlet } = parameters;
        const {
            OperationDashboardStore: {
                handleGetAreaByOutletData,
                handleGetOperationDashboardInfo
            }
        } = this.props;

        if (token) {
            Cookies.set('token', token);
        }

        handleGetAreaByOutletData().then(() => {
            const { areas, businessManagers, territoryManagers, outlets } = this.props.OperationDashboardStore;

            this.setState({
                selectedAreas: area ? area.split(',').map(value => areas.find(a => a.value === value) || { value, label: value }) : [],
                selectedBusinessManagers: businessManager ? businessManager.split(',').map(value => businessManagers.find(bm => bm.value === value) || { value, label: value }) : [],
                selectedTerritoryManagers: territoryManager ? territoryManager.split(',').map(value => territoryManagers.find(tm => tm.value === value) || { value, label: value }) : [],
                selectedOutlets: outlet ? outlet.split(',').map(value => outlets.find(o => o.value === value) || { value, label: value }) : [],
            });
        });

        this.refreshInterval = setInterval(() => {
            handleGetOperationDashboardInfo(this.state.filteredOutlets);
        }, 1000 * 60);

        setTimeout(() => {
            this.autoScrollInterval = setInterval(this.handleAutoScroll, 1000 * 10);
        }, 0);

        this.disposeLoadingReaction = reaction(
            () => this.props.OperationDashboardStore.dashboardInfoLoading,
            (loadingNow, previousLoading) => {
                if (!loadingNow && previousLoading) {
                    setTimeout(() => this.measureRowOffsets(), 0);
                }
            }
        );
    }

    componentWillUnmount() {
        clearInterval(this.refreshInterval);
        clearInterval(this.autoScrollInterval);
        if (this.disposeLoadingReaction) this.disposeLoadingReaction();
    }

    handleFilterChange = (filterName, selectedOptions) => {
        this.setState({
            [filterName]: selectedOptions,
        });
    };

    componentDidUpdate(prevProps, prevState) {
        const isAnyfilterSelected = this.state.selectedAreas.length > 0 || this.state.selectedBusinessManagers.length > 0 || this.state.selectedTerritoryManagers.length > 0 || this.state.selectedOutlets.length > 0;
        if (
            isAnyfilterSelected && (prevState.selectedAreas !== this.state.selectedAreas ||
                prevState.selectedBusinessManagers !== this.state.selectedBusinessManagers ||
                prevState.selectedTerritoryManagers !== this.state.selectedTerritoryManagers ||
                prevState.selectedOutlets !== this.state.selectedOutlets) 
        ) {
            this.filterStores();
        }
    }

    // ---- Auto-scroll logic ----

    measureRowOffsets = () => {
        // Find all card elements
        const cards = document.querySelectorAll(`.${base}__card`);
        if (!cards.length) return;

        // Convert to array and gather measurements
        const cardPositions = Array.from(cards).map((card) => {
            const rect = card.getBoundingClientRect();
            const top = rect.top + window.scrollY;
            const height = rect.height; // measure the card's height
            return { card, top, height };
        });

        // Sort by vertical offset
        cardPositions.sort((a, b) => a.top - b.top);

        // Group cards by “row” using a small threshold
        const rowOffsets = [];
        const threshold = 10; // tolerance (pixels) for grouping

        let currentRowTop = null;
        let currentRowCards = []; // keep track of cards that belong to this row

        // Helper function to finalize a row
        const finalizeRow = (rowTop, rowCards) => {
            // The row's total height is the tallest card in this row
            if (!rowCards.length) return;
            const rowHeight = Math.max(...rowCards.map((c) => c.height));
            // Add multiple offsets for this row if it's tall
            this.addRowOffsets(rowOffsets, rowTop, rowHeight);
        };

        cardPositions.forEach(({ card, top, height }) => {
            if (currentRowTop === null) {
                // first row
                currentRowTop = top;
                currentRowCards = [{ card, top, height }];
                return;
            }

            if (Math.abs(top - currentRowTop) <= threshold) {
                // still in the same row
                currentRowCards.push({ card, top, height });
            } else {
                // we've moved to a new row -> finalize the old row
                finalizeRow(currentRowTop, currentRowCards);

                // start a new row
                currentRowTop = top;
                currentRowCards = [{ card, top, height }];
            }
        });

        // finalize the last row
        finalizeRow(currentRowTop, currentRowCards);

        this.setState({
            rowOffsets,
            currentRowIndex: 0
        });
    };

    addRowOffsets = (rowOffsetsArray, rowTop, rowHeight) => {
        const windowHeight = window.innerHeight;
        const increment = Math.floor(windowHeight * 0.8);

        let offset = rowTop;

        while (offset < rowTop + rowHeight) {
            rowOffsetsArray.push(offset);
            offset += increment;
        }
    };

    handleAutoScroll = () => {
        const { rowOffsets, currentRowIndex } = this.state;
        if (!rowOffsets.length) return;

        // If we pass the last row, loop back to the first row offset
        if (currentRowIndex >= rowOffsets.length) {
            const firstOffset = rowOffsets[0] - 20;
            window.scrollTo({ top: firstOffset, behavior: 'smooth' });
            this.setState({ currentRowIndex: 1 }); // Reset index to the second row
            return;
        }

        // Otherwise, scroll to the next row offset, minus a small margin
        const targetOffset = rowOffsets[currentRowIndex] - 20;

        // Don’t scroll beyond the bottom
        const maxScroll = document.documentElement.scrollHeight - window.innerHeight;
        const finalOffset = Math.min(targetOffset, maxScroll);

        window.scrollTo({ top: finalOffset, behavior: 'smooth' });
        this.setState({ currentRowIndex: currentRowIndex + 1 });
    }

    filterStores = () => {
        const { OperationDashboardStore } = this.props;
        const { areas, areaOutletMap, businessManagers, businessManagerOutletMap, 
            territoryManagers, territoryManagerOutletMap, outlets, handleGetOperationDashboardInfo } = OperationDashboardStore;
        // Get selected values
        let selectedAreas = this.state.selectedAreas.map((area) => area.value);
        if (selectedAreas.includes("All")) {
            selectedAreas = areas.map((area) => area.value);
        }
        const areaOutlets = selectedAreas.flatMap((area) => areaOutletMap[area] || []);

        let selectedBusinessManagers = this.state.selectedBusinessManagers.map((manager) => manager.value);
        if (selectedBusinessManagers.includes("All")) {
            selectedBusinessManagers = businessManagers.map((manager) => manager.value);
        }
        const businessManagerOutlets = selectedBusinessManagers.flatMap((manager) => businessManagerOutletMap[manager] || []);

        let selectedTerritoryManagers = this.state.selectedTerritoryManagers.map((manager) => manager.value);
        if (selectedTerritoryManagers.includes("All")) {
            selectedTerritoryManagers = territoryManagers.map((manager) => manager.value);
        }
        const territoryManagerOutlets = selectedTerritoryManagers.flatMap((manager) => territoryManagerOutletMap[manager] || []);

        let selectedOutlets = this.state.selectedOutlets.map((outlet) => outlet.value);
        if (selectedOutlets.includes("All")) {
            selectedOutlets = outlets.map((outlet) => outlet.value);
        }
        const outletValues = outlets.map((outlet) => outlet.value);

        const filteredOutlets = [
            ...(new Set(outletValues.filter(outlet =>
                (!selectedAreas.length || areaOutlets.includes(outlet)) &&
                (!selectedBusinessManagers.length || businessManagerOutlets.includes(outlet)) &&
                (!selectedTerritoryManagers.length || territoryManagerOutlets.includes(outlet)) &&
                (!selectedOutlets.length || selectedOutlets.includes(outlet))
            )))
        ];

        // Fetch the data for these outlets
        handleGetOperationDashboardInfo(filteredOutlets);
        this.setState({ filteredOutlets }, () => {
            // After new data finishes rendering new cards,
            // re-measure the row offsets so auto-scroll targets are up-to-date.
            setTimeout(() => this.measureRowOffsets(), 0);
        });
    };

    handleError = (err) => {};

    handleScanWrapper = (scannedBarcode) => {
        const { LiveDispatchStore, ScanningStore } = this.props;
        const { setItemImage, handledScannedItem, handledScannedBag, handledScannedWarmerItem, displayErrorResultFunc } =
            LiveDispatchStore;
        const { handleScan } = ScanningStore;
        if (this.webcamRef.current) {
            const image = this.webcamRef.current.getScreenshot();
            setItemImage(image);
        }
        let isBagQRCode = scannedBarcode.split('-').length == 1;
        if (isBagQRCode) {
            handledScannedBag(scannedBarcode);
        } else {
            handleScan(scannedBarcode, handledScannedItem, handledScannedWarmerItem, displayErrorResultFunc);
        }
    };

    render() {
        const { OperationDashboardStore, LiveDispatchStore } = this.props;

        const {
            closedPopmealsStores,
            closedVirtualBrands,
            areas,
            businessManagers,
            territoryManagers,
            outlets,
            noOutletManagerClockedIn,
            understaffedOutlets,
            allIpadsOffline,
            outletWithLessMenuItem,
            outletWithTopFiveOOS,
            receivingNotDone,
            cardTerminalNotConnected,
            noDitaSalesInTwoHours,
            noDeliverySalesInTwoHours,
            criticalComponentsOutOfStock,
            loading
        } = OperationDashboardStore;

        if (loading) {
            return <Loading />
        }

        const {
            selectedAreas,
            selectedBusinessManagers,
            selectedTerritoryManagers,
            selectedOutlets,
            filteredOutlets
        } = this.state;

        const filterApplied = Object.keys(this.state.selectedAreas).length > 0 ||
                Object.keys(this.state.selectedBusinessManagers).length > 0 ||
                Object.keys(this.state.selectedTerritoryManagers).length > 0 ||
                Object.keys(this.state.selectedOutlets).length > 0;

        const {
            itemImage: image,
            itemScanLoading,
            scannedItem,
            scannedItemSKU,
            scannedItemError,
            showScannedItemOnLiveDispatch,
            originalItemImageLink,
        } = LiveDispatchStore;

        return (
            <>
                <Helmet>
                    <title>Operation Dashboard | Outlet OS | Pop Meals</title>
                </Helmet>
                <div>
                    <div className={`${base}__webcam-area`}>
                        <Webcam
                            audio={false}
                            ref={this.webcamRef}
                            screenshotFormat="image/jpeg"
                            className="webcam"
                            screenshotQuality={1}
                            forceScreenshotSourceSize={true}
                            videoConstraints={{
                                height: 1080,
                                width: 1920,
                            }}
                        />
                    </div>
                    <div className={`${base}__filters`}>
                        <Select
                            isMulti
                            options={areas.slice()}
                            value={selectedAreas}
                            onChange={(selected) => this.handleFilterChange('selectedAreas', selected)}
                            placeholder="Select Areas"
                            className={`${base}__filter`}
                        />
                        <Select
                            isMulti
                            options={businessManagers.slice()}
                            value={selectedBusinessManagers}
                            onChange={(selected) => this.handleFilterChange('selectedBusinessManagers', selected)}
                            placeholder="Select Business Managers"
                            className={`${base}__filter`}
                        />
                        <Select
                            isMulti
                            options={territoryManagers.slice()}
                            value={selectedTerritoryManagers}
                            onChange={(selected) => this.handleFilterChange('selectedTerritoryManagers', selected)}
                            placeholder="Select Territory Managers"
                            className={`${base}__filter`}
                        />
                        <Select
                            isMulti
                            options={outlets.slice()}
                            value={selectedOutlets}
                            onChange={(selected) => this.handleFilterChange('selectedOutlets', selected)}
                            placeholder="Select Outlets"
                            className={`${base}__filter`}
                        />
                    </div>

                    {!filterApplied && (
                        <h1>
                            Select filters
                        </h1>
                    )}

                    {(filterApplied && filteredOutlets.length == 0) && (
                        <h1>
                            No outlet match the filters
                        </h1>
                    )}

                    {(filterApplied && filteredOutlets.length > 0) && (
                        <div className="row" style={{ margin: '0px' }}>
                            {closedPopmealsStores && Object.keys(closedPopmealsStores).length > 0 && (
                                <div className="col-sm-4">
                                    <div className={`${base}__card`} style={{marginLeft: '10px', marginRight: '10px'}}>
                                        <p className={`${base}__card_header`}>Closed stores (Pop Meals)</p>
                                        <ul>
                                            {Object.entries(closedPopmealsStores).map(([key, value]) => (
                                                <li key={key} style={{paddingRight: '6px', paddingBottom: '6px'}}>
                                                    {key} - {value.join(", ")}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            )}

                            {closedVirtualBrands && Object.keys(closedVirtualBrands).length > 0 && (
                                <div className="col-sm-4">
                                    <div className={`${base}__card`} style={{marginLeft: '10px', marginRight: '10px'}}>
                                        <p className={`${base}__card_header`}>Closed stores (VB)</p>
                                        <ul>
                                            {Object.entries(closedVirtualBrands).map(([key, value]) => (
                                                <li key={key} style={{paddingRight: '6px', paddingBottom: '6px'}}>
                                                    {key} - {value.join(", ")}
                                                </li>
                                            ))}
                                        </ul>
                                    </div> 
                                </div>
                            )}

                            {noOutletManagerClockedIn && noOutletManagerClockedIn.length > 0 && (
                                <div className="col-sm-4">
                                    <div className={`${base}__card`} style={{marginLeft: '10px', marginRight: '10px'}}>
                                        <p className={`${base}__card_header`}>No manager/ assistant manager clocked in</p>
                                        <ul>
                                            {noOutletManagerClockedIn.map((entry) => (
                                                <li style={{paddingRight: '6px', paddingBottom: '6px'}}>
                                                    {entry}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            )}

                            {understaffedOutlets && understaffedOutlets.length > 0 && (
                                <div className="col-sm-4">
                                    <div className={`${base}__card`} style={{marginLeft: '10px', marginRight: '10px'}}>
                                        <p className={`${base}__card_header`}>Understaffed Outlets</p>
                                        <ul>
                                            {understaffedOutlets.map((entry) => (
                                                <li style={{paddingRight: '6px', paddingBottom: '6px'}}>
                                                    {entry}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            )}

                            {allIpadsOffline && allIpadsOffline.length > 0 && (
                                <div className="col-sm-4">
                                    <div className={`${base}__card`} style={{marginLeft: '10px', marginRight: '10px'}}>
                                        <p className={`${base}__card_header`}>All iPads Offline</p>
                                        <ul>
                                            {allIpadsOffline.map((entry) => (
                                                <li style={{paddingRight: '6px', paddingBottom: '6px'}}>
                                                    {entry}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            )}

                            {outletWithLessMenuItem && outletWithLessMenuItem.length > 0 && (
                                <div className="col-sm-4">
                                    <div className={`${base}__card`} style={{marginLeft: '10px', marginRight: '10px'}}>
                                        <p className={`${base}__card_header`}>Outlets with Less than 10 meals on menu</p>
                                        <ul>
                                            {outletWithLessMenuItem.map((entry) => (
                                                <li style={{paddingRight: '6px', paddingBottom: '6px'}}>
                                                    {entry}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            )}

                            {outletWithTopFiveOOS && outletWithTopFiveOOS.length > 0 && (
                                <div className="col-sm-4">
                                    <div className={`${base}__card`} style={{marginLeft: '10px', marginRight: '10px'}}>
                                        <p className={`${base}__card_header`}>Outlets with any of the top 5 meals OOS</p>
                                        <ul>
                                            {outletWithTopFiveOOS.map((entry) => (
                                                <li style={{paddingRight: '6px', paddingBottom: '6px'}}>
                                                    {entry}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            )}

                            {receivingNotDone && receivingNotDone.length > 0 && (
                                <div className="col-sm-4">
                                    <div className={`${base}__card`} style={{marginLeft: '10px', marginRight: '10px'}}>
                                        <p className={`${base}__card_header`}>Expected receipts today not done</p>
                                        <ul>
                                            {receivingNotDone.map((entry) => (
                                                <li style={{paddingRight: '6px', paddingBottom: '6px'}}>
                                                    {entry}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            )}

                            {cardTerminalNotConnected && cardTerminalNotConnected.length > 0 && (
                                <div className="col-sm-4">
                                    <div className={`${base}__card`} style={{marginLeft: '10px', marginRight: '10px'}}>
                                        <p className={`${base}__card_header`}>Card terminal not connected</p>
                                        <ul>
                                            {cardTerminalNotConnected.map((entry) => (
                                                <li style={{paddingRight: '6px', paddingBottom: '6px'}}>
                                                    {entry}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            )}

                            {noDitaSalesInTwoHours && noDitaSalesInTwoHours.length > 0 && (
                                <div className="col-sm-4">
                                    <div className={`${base}__card`} style={{ marginLeft: '10px', marginRight: '10px' }}>
                                        <p className={`${base}__card_header`}>No sales in past two hours - Dine In & Pick Up</p>
                                        <ul>
                                            {noDitaSalesInTwoHours.map((entry) => (
                                                <li style={{ paddingRight: '6px', paddingBottom: '6px' }}>
                                                    {entry}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            )}

                            {noDeliverySalesInTwoHours && noDeliverySalesInTwoHours.length > 0 && (
                                <div className="col-sm-4">
                                    <div className={`${base}__card`} style={{ marginLeft: '10px', marginRight: '10px' }}>
                                        <p className={`${base}__card_header`}>No sales in past two hours - Delivery</p>
                                        <ul>
                                            {noDeliverySalesInTwoHours.map((entry) => (
                                                <li style={{ paddingRight: '6px', paddingBottom: '6px' }}>
                                                    {entry}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            )}

                            {criticalComponentsOutOfStock && Object.keys(criticalComponentsOutOfStock).length > 0 && (
                                <div className="col-sm-4">
                                    <div className={`${base}__card`} style={{marginLeft: '10px', marginRight: '10px'}}>
                                        <div className={`${base}__card_header`}>Critical component out of stock</div>
                                        <ul>
                                            {Object.entries(criticalComponentsOutOfStock).map(([key, value]) => (
                                                <li key={key} style={{paddingRight: '6px', paddingBottom: '6px'}}>
                                                    {key} - {value.join(", ")}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                    <BarcodeReader
                        onError={this.handleError}
                        onScan={this.handleScanWrapper}
                    />
                    <ScanningBox
                        show={showScannedItemOnLiveDispatch}
                        scannedItem={scannedItem}
                        scannedItemSKU={scannedItemSKU}
                        scannedItemError={scannedItemError}
                        handleScan={this.handleScanWrapper}
                        loading={itemScanLoading}
                        originalItemImageLink={originalItemImageLink}
                        itemImage={image}
                        isCurrentPageFullScreen={false}
                    />
                </div>
            </>
        );
    }
}

export default OperationDashboard;
