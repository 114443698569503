import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import InventoryComponentBatchHistoryModal from './InventoryComponentBatchHistoryModal';
import ComponentStockStore from "../../stores/ComponentStockStore";
import InventoryStore from "../../stores/InventoryStore";

const base = 'sku-entry';

@inject('ComponentStockStore')
@observer
class UnitSkuStockInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            quantityPerPackaging: [],
        };
    }

    componentDidMount = () => {
        const {
            batch: {unit_sku},
            quantityPerPackaging
        }= this.props;
        let packaging = '';
        if (quantityPerPackaging) {
            packaging = quantityPerPackaging;
        } else {
            packaging = ComponentStockStore.getPackaging({sku: unit_sku});
        }
        this.setState({quantityPerPackaging: packaging});
    }

    handleChange = (e) => {
        const { onChange } = this.props;
        const { name, value, dataset } = e.target;
        let {
            quantityPerPackaging
        }= this.state;
        quantityPerPackaging.filter(p => p.id === name)[0].quantity = parseInt(value);
        this.setState({quantityPerPackaging});
        onChange(dataset.sku, this.calculateUnitQuantity(), quantityPerPackaging);
    };

    calculateUnitQuantity = () => {
        const {
            quantityPerPackaging
        }= this.state;
        let totalQuantity = 0;
        for (const packaging of quantityPerPackaging) {
            if (!packaging.quantity) {
                continue;
            }
            totalQuantity += packaging.quantity * packaging.innerQuantity;
        }
        return totalQuantity;
    }

    lastUpdated = (stock_check_tracker) => {
        const stockCheckTransferLog =
            stock_check_tracker &&
            stock_check_tracker.filter((entry) => {
                const { updated_at, reason } = entry;
                return reason === 'DAILY_STOCK_CHECK' && InventoryStore.isUpdatedInTimeFrame(updated_at);
            });

        return (
            stockCheckTransferLog &&
            stockCheckTransferLog[stockCheckTransferLog.length - 1]
        );
    }

    render() {
        const {
            batch: {
                unit_sku,
                stock_check_tracker,
                isCheckCompleted,
                name
            },
            value,
            isResubmit,
        } = this.props;
        const lastUpdatedLog = this.lastUpdated(stock_check_tracker);
        const count = isResubmit
            ? value
            : isCheckCompleted
                ? lastUpdatedLog.quantity
                : value;
        const {
            quantityPerPackaging
        } = this.state;
        let isFirstPackaging = true;

        return (
            <Fragment>
                <div className={`fs--medium-large ps bg--black text--white mts`}>
                    {unit_sku + ' : ' + name}
                </div>
                <div className={`${base} display--flex justify--center`} style={{paddingBottom: '0px'}}>                    
                    <div className="col-lg-6" style={{width: '500px'}}>
                            <div className="display--flex width--100 gap--10 pts">
                                <label className='fs--medium-medium-large' style={{width: '100px'}}>Quantity</label>
                                <label className='fs--medium-medium-large'>Packaging</label>
                            </div>
                            {quantityPerPackaging && quantityPerPackaging.map((p, index) => (
                                <Fragment key={p.id}>
                                    {index > 0 && <div className="plus-separator text--center "><label className='fs--medium-medium-large'>+</label></div>}
                                    <div className="input-row display--flex justify--center gap--10">
                                        <input
                                            className='quantity-input border--rounded border--solid fs--medium-medium-large text--center text--black text--bolder ps'
                                            type='number'
                                            name={p.id}
                                            data-sku={unit_sku}
                                            min={0}
                                            value={p.quantity === undefined ? '' : p.quantity}
                                            onChange={this.handleChange}
                                        />
                                        <p className='packaging-label fs--medium-medium-large text--left text--black text--sofiapro'>
                                            {p.packaging}
                                        </p>
                                    </div>
                                </Fragment>
                            ))}                        
                    </div>
                    <div className="fixed-bottom-container width--100 display--flex justify--center">
                        <div className="history-modal-wrapper">
                            <InventoryComponentBatchHistoryModal
                                history={stock_check_tracker}
                            />
                            {lastUpdatedLog && (
                                <div className="last-updated-info">
                                    <h3>Last updated</h3>
                                    <p>User: {lastUpdatedLog.initiator_username}</p>
                                    <p>
                                        Time:{' '}
                                        {moment(
                                            lastUpdatedLog.updated_at,
                                            'x'
                                        ).calendar()}
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <style jsx>{`
                    .input-row {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        margin: 0.5rem 0;
                    }

                    .quantity-input {
                        width: 100px;
                        min-width: 100px;
                    }

                    .packaging-label {
                        flex: 1;
                        margin: 0;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    .plus-separator {
                        padding: 0.5rem 0;
                        width: 100px;
                    }

                    .fixed-bottom-container {
                        position: fixed;
                        bottom: 100px; /* Adjust this value based on your footer height */
                        z-index: 0;
                    }

                    .history-modal-wrapper {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                    }

                    .last-updated-info {
                        margin-top: 10px;
                        font-size: 0.9em;
                        text-align: right;
                    }
                `}</style>
            </Fragment>
        );
    }
}

export default UnitSkuStockInput;
